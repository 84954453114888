<template>
    <canvas :ref="`pie-chart${id}`"></canvas>
</template>

<script>
import Chart from "chart.js/auto";

export default {
  props: {
    chartData: {
      type: Object,
      default: null,
    },
    options: {
      type: Object,
      default() {
        return {
          responsive: true,
          maintainAspectRatio: true,
        };
      },
    },
  },
  watch: {
    chartData: {
      handler() {
        this.chart.update();
      },
      deep: true,
    },
  },
  data() {
    return {
      id: Math.random(),
      chart: null,
    };
  },
  mounted() {
    // eslint-disable-next-line no-unused-vars
    this.chart = new Chart(this.$refs[`pie-chart${this.id}`], {
      type: "doughnut",
      data: this.chartData,
      options: this.options,
    });
  },
};
</script>
