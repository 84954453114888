<!-- eslint-disable no-continue -->
<template>
  <div>
    <div class="session-box practice-session">
      <div class="header-box relative" :style="`background: ${subBgColor};`">
        <div class="header-options flex content-btw">
          <div class="header-right">
            <button
              v-if="this.isPastYear === 0"
              class="header-right-button"
              @click="pauseModal = true"
            >
              <img :src="require(`../assets/button-arrow.svg`)" class="center-align" />
            </button>
            <button
              v-if="this.isPastYear === 1"
              class="header-right-button"
              @click="endModal = true"
            >
              <img :src="require(`../assets/button-arrow.svg`)" class="center-align" />
            </button>
            <div class="flex column content-btw" style="height: 59px">
              <span class="fw-700 fs-20 blk-text ln-27 chapter-name">{{
                selectedQuestionData.chapterName
              }}</span>
              <span class="fw-400 fs-18 blk-text ln-18 flex class-name"
                >{{ className }}
                <i class="material-icons" style="margin-top: -3px">keyboard_arrow_right</i>
                {{ subjectName }}
              </span>
            </div>
          </div>
          <div>
            <img
              :src="subjectIconURL"
              style="opacity: 0.2; height: 72px; width: 75px; margin-top: -7px"
            />
          </div>
          <div class="header-left flex">
            <img
              v-if="testTypeId !== 2 && this.isPastYear === 0"
              style="margin-right: 10px"
              class="button-size"
              :src="require(`../assets/pause-svg.svg`)"
              @click="pauseModal = true"
            />
            <img
              v-if="testTypeId !== 2"
              style="margin-right: 10px"
              class="button-size"
              :src="require(`../assets/end-svg.svg`)"
              @click="endModal = true"
            />
            <img
              v-if="testTypeId === 2"
              style="margin-right: 10px"
              class="button-size"
              :src="require(`../assets/end-svg.svg`)"
              @click="showUploadLater()"
            />
            <img
              style="margin-right: 10px"
              class="button-size"
              :src="require(`../assets/warn-svg.svg`)"
              @click="reportModal = true"
            />
            <!-- <button style="margin-right:10px" class="score-button fs-14 fw-600" @click="showScore = true">View Score</button> -->
          </div>
        </div>
        <div class="score-card" v-if="showScore" v-click-outside="closeScore">
          <div class="flex content-btw earn-head-1">
            <div class="flex content-btw" style="margin-left: 21px">
              <span class="fw-600 fs-15">Score</span>
            </div>
            <p
              @click="showScore = false"
              style="position: absolute; right: 17px; cursor: pointer; top: 0px"
            >
              <span class="material-icons earn-close" style="color: #ffffff !important">close</span>
            </p>
          </div>
          <div class="flex content-btw">
            <div class="graph-area" style="width: 50%; height: 418px; padding: 20px">
              <span class="fs-15 fw-700">Your Accuracy</span>
              <div class="flex content-btw" style="margin-top: 23px">
                <div class="flex content-btw" style="width: 65px">
                  <div class="green-indicator"></div>
                  <span class="fs-13 fw-400">Correct</span>
                </div>
                <div class="flex content-btw" style="width: 75px">
                  <div class="red-indicator"></div>
                  <span class="fs-13 fw-400">Incorrect</span>
                </div>
                <div class="flex content-btw" style="width: 70px">
                  <div class="yellow-indicator"></div>
                  <span class="fs-13 fw-400">Skipped</span>
                </div>
              </div>
              <div class="relative">
                <doughnut-chart :chartData="pieChartData" :Options="pieChartOptions" />
                <span class="fs-13 fw-400" style="position: absolute; top: 112px; right: 110px"
                  >Accuracy</span
                >
                <span class="fs-24 fw-600" style="position: absolute; top: 132px; right: 95px"
                  >42.53%</span
                >
              </div>
              <span class="flex content-cntr center-align fs-15 fw-400" style="margin-top: 10px"
                ><span class="fw-600">02:43 &nbsp;</span> Average Time</span
              >
            </div>
            <div class="card-area" style="width: 50%">
              <span class="fs-15 fw-700">Conceptual Performance</span>
              <div class="concept-list">
                <div class="flex column">
                  <div class="concept-card">
                    <div style="margin: 13px 21px 15px 15px">
                      <span class="fs-12 fw-600">Principles of Trigonometry</span>
                      <div>
                        <img :src="require(`../assets/Question1.svg`)" />
                        <span></span>
                        <img :src="require(`../assets/target.svg`)" />
                        <img :src="require(`../assets/hourglass.svg`)" />
                      </div>
                    </div>
                  </div>
                  <div class="concept-card"></div>
                  <div class="concept-card"></div>
                  <div class="concept-card"></div>
                  <div class="concept-card"></div>
                  <div class="concept-card"></div>
                  <div class="concept-card"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="conceptDiv === false">
        <div class="flex" style="justify-content: flex-end; margin-top: 8px; margin-bottom: 8px">
          <div class="question-type-button">{{ selectedQuestionData.QuestionType }}</div>
          <div v-if="selectedQuestionData.DifficultyGroup === 'Basic'" class="basic-button">
            Basic
          </div>
          <div v-if="selectedQuestionData.DifficultyGroup === 'Easy'" class="easy-button">Easy</div>
          <div v-if="selectedQuestionData.DifficultyGroup === 'Normal'" class="normal-button">
            Normal
          </div>
          <div v-if="selectedQuestionData.DifficultyGroup === 'Hard'" class="hard-button">Hard</div>
          <div v-if="selectedQuestionData.DifficultyGroup === 'Tough'" class="tough-button">
            Tough
          </div>
        </div>
        <div v-if="questionLatexStatus === 'Y'" class="latex-section">
          <div v-if="questionLatex !== ''" class="row">
            <vue-mathjax
              :formula="questionLatex"
              :options="VueMathJaxOptions"
              :safe="false"
              class="space-break fs-18 fw-500"
            />
          </div>
          <div
            v-if="questionLatexDiagram !== null"
            class="row"
            :style="{
              'min-width': `${questionPosition[2] * 720}px`,
              'max-width': `${questionPosition[2] * 100}%`,
            }"
          >
            <img :src="getAzureUrl(questionDiagram)" />
          </div>
          <div class="row">
            <div
              class="col s6"
              style="display: flex; align-items: center"
              v-if="questionOptionA !== null"
            >
              <span class="selection-btn">A</span
              ><vue-mathjax
                :formula="questionOptionA"
                :options="VueMathJaxOptions"
                :safe="false"
                class="space-break fs-18 fw-500"
              />
            </div>
            <div
              class="col s6"
              style="display: flex; align-items: center"
              v-if="questionOptionB !== null"
            >
              <span class="selection-btn">B</span
              ><vue-mathjax
                :formula="questionOptionB"
                :options="VueMathJaxOptions"
                :safe="false"
                class="space-break fs-18 fw-500"
              />
            </div>
          </div>
          <div class="row">
            <div
              class="col s6"
              style="display: flex; align-items: center"
              v-if="questionOptionC !== null"
            >
              <span class="selection-btn">C</span
              ><vue-mathjax
                :formula="questionOptionC"
                :options="VueMathJaxOptions"
                :safe="false"
                class="space-break fs-18 fw-500"
              />
            </div>
            <div
              class="col s6"
              style="display: flex; align-items: center"
              v-if="questionOptionD !== null"
            >
              <span class="selection-btn">D</span
              ><vue-mathjax
                :formula="questionOptionD"
                :options="VueMathJaxOptions"
                :safe="false"
                class="space-break fs-18 fw-500"
              />
            </div>
          </div>
        </div>
        <div v-else class="image-section">
          <img :src="questionImage" class="question-image" style="" draggable="false" />
        </div>
        <div
          :class="{
            'answer-section': questionLatexStatus !== 'Y',
            'answer-section-latex': questionLatexStatus === 'Y',
          }"
        >
          <div v-if="showOptions" class="answer-objective flex content-btw">
            <div
              class="flex content-btw answer-objective-div"
              style="max-width: 745px; width: 740px"
              :style="showRevisionModal ? 'filter: blur(2px) !important' : ''"
            >
              <div class="flex">
                <button
                  class="answer-button fw-600 fs-20"
                  :class="{ active: selectedOption === 'A', deactive: answerGiven === 'A' }"
                  value="A"
                  @click="checkAnswer($event)"
                >
                  A
                </button>
                <button
                  class="answer-button fw-600 fs-20"
                  :class="{ active: selectedOption === 'B', deactive: answerGiven === 'B' }"
                  value="B"
                  @click="checkAnswer($event)"
                >
                  B
                </button>
                <button
                  class="answer-button fw-600 fs-20"
                  :class="{ active: selectedOption === 'C', deactive: answerGiven === 'C' }"
                  value="C"
                  @click="checkAnswer($event)"
                >
                  C
                </button>
                <button
                  class="answer-button fw-600 fs-20"
                  :class="{ active: selectedOption === 'D', deactive: answerGiven === 'D' }"
                  value="D"
                  @click="checkAnswer($event)"
                >
                  D
                </button>
              </div>
              <div
                class="flex content-cntr"
                :style="showRevisionModal ? 'filter: blur(2px) !important' : ''"
              >
                <hr class="answer-section-hr" style="margin-top: 24px" />
                <button
                  class="submit-button fw-600 fs-13 ln-18"
                  :class="{ active: selectedOption !== '' }"
                  style="margin-top: 8px"
                  @click="submitAnswer()"
                >
                  SUBMIT
                </button>
                <button
                  class="skip-button fw-600 fs-13 ln-18"
                  style="margin-top: 8px"
                  @click="skipQuestion()"
                >
                  SKIP
                </button>
              </div>
            </div>
            <div style="margin-top: 3px !important">
              <button
                class="option-button center-align"
                @click="showOther()"
                style="background: #693383"
              >
                <img class="option-img" :src="require(`../assets/threedots.svg`)" />
              </button>
            </div>
            <div
              v-if="showRevisionModal"
              class="revision-modal flex content-even"
              style="top: 0 !important; right: 0 !important"
            >
              <div class="flex flex-column content-even">
                <button
                  v-if="showRevision === false && this.markForRevisionQuestionId === null"
                  class="flex content-left revision-button fs-13 fw-600"
                  @click="markForRevision()"
                >
                  <img class="revision-img" :src="require(`../assets/revise-load.svg`)" />Add to
                  Revision
                </button>
                <button
                  v-if="showRevision === false && this.markForRevisionQuestionId !== null"
                  class="flex content-left revision-button fs-13 fw-600"
                  @click="removeRevision()"
                >
                  <img class="revision-img" :src="require(`../assets/revise-load.svg`)" />Remove
                  from Revision
                </button>
                <button
                  v-if="showRevision === true && this.markForRevisionQuestionId === null"
                  class="flex content-left revision-button-1 fs-13 fw-600"
                >
                  <img class="revision-img-1" :src="require(`../assets/checked-svg.svg`)" />Added to
                  Revision
                </button>
                <button
                  v-if="showRevision === true && this.markForRevisionQuestionId !== null"
                  class="flex content-left revision-button-1 fs-13 fw-600"
                >
                  <img class="revision-img-1" :src="require(`../assets/checked-svg.svg`)" />Removed
                  from Revision
                </button>
                <button
                  v-if="showClarification === false"
                  class="flex content-left revision-button fs-13 fw-600"
                  @click="markQuesForClarification()"
                  :class="{
                    'blue-bg': isMarkForClarification,
                    'white-font': isMarkForClarification,
                  }"
                >
                  <img
                    class="revision-img"
                    :src="require(`../assets/problem-solution.svg`)"
                    :class="{ 'white-bg': isMarkForClarification }"
                  />{{ markTextHandler }} for Clarification
                </button>
                <button
                  v-if="showClarification === true"
                  class="flex content-left revision-button-1 fs-13 fw-600"
                  @click="showClarification = false"
                >
                  <img class="revision-img-1" :src="require(`../assets/checked-svg.svg`)" />Mark for
                  Clarification
                </button>
              </div>
              <div class="flex flex-column content-cntr">
                <button class="modal-close" @click="closeModal()">
                  <span class="material-icons" style="color: #ffffff !important">close</span>
                </button>
              </div>
            </div>
          </div>
          <div v-if="correctAnswer" class="correct-section flex content-btw relative">
            <div class="flex" :style="showRevisionModal ? 'filter: blur(2px) !important' : ''">
              <img
                :src="require(`../assets/correct-answer.svg`)"
                class="center-align correct-image"
              />
              <div class="flex column">
                <span class="fw-700 fs-15 blue-text ln-20">Correct Answer !</span>
                <span class="fw-400 fs-13 blk-text ln-18"
                  ><b>{{ selectedQuestionData.Accuracy }}</b> solved this question correctly in
                  <b>{{ selectedQuestionData.AvgTime }}</b></span
                >
              </div>
            </div>
            <div
              class="flex relative"
              :style="showRevisionModal ? 'filter: blur(2px) !important' : ''"
            >
              <hr class="answer-section-hr" style="margin-top: 24px" />
              <button
                class="concept-button fw-600 fs-13 ln-18"
                style="margin-top: 8px"
                @click="showSolution()"
              >
                VIEW SOLUTION
              </button>
              <!-- <button class="similar-button fw-600 fs-13 ln-18" style="margin-top: 8px;" @click="similarQuestions()">SIMILAR QUESTION</button> -->
              <button
                class="next-button fw-600 fs-13 ln-18"
                style="margin-top: 8px"
                @click="nextQuestion()"
              >
                NEXT
              </button>
              <button
                class="option-button center-align"
                @click="showOther()"
                style="margin-top: 3px !important; background: #693383"
              >
                <img class="option-img" :src="require(`../assets/threedots.svg`)" />
              </button>
            </div>
            <div v-if="showRevisionModal" class="revision-modal flex content-even">
              <div class="flex flex-column content-even">
                <button
                  v-if="showRevision === false && this.markForRevisionQuestionId === null"
                  class="flex content-left revision-button fs-13 fw-600"
                  @click="markForRevision()"
                >
                  <img class="revision-img" :src="require(`../assets/revise-load.svg`)" />Add to
                  Revision
                </button>
                <button
                  v-if="showRevision === false && this.markForRevisionQuestionId !== null"
                  class="flex content-left revision-button fs-13 fw-600"
                  @click="removeRevision()"
                >
                  <img class="revision-img" :src="require(`../assets/revise-load.svg`)" />Remove
                  from Revision
                </button>
                <button
                  v-if="showRevision === true && this.markForRevisionQuestionId === null"
                  class="flex content-left revision-button-1 fs-13 fw-600"
                >
                  <img class="revision-img-1" :src="require(`../assets/checked-svg.svg`)" />Added to
                  Revision
                </button>
                <button
                  v-if="showRevision === true && this.markForRevisionQuestionId !== null"
                  class="flex content-left revision-button-1 fs-13 fw-600"
                >
                  <img class="revision-img-1" :src="require(`../assets/checked-svg.svg`)" />Removed
                  from Revision
                </button>
                <button
                  v-if="showClarification === false"
                  class="flex content-left revision-button fs-13 fw-600"
                  @click="markQuesForClarification()"
                  :class="{
                    'blue-bg': isMarkForClarification,
                    'white-font': isMarkForClarification,
                  }"
                >
                  <img
                    class="revision-img"
                    :src="require(`../assets/problem-solution.svg`)"
                    :class="{ 'white-bg': isMarkForClarification }"
                  />{{ markTextHandler }} for Clarification
                </button>
                <button
                  v-if="showClarification === true"
                  class="flex content-left revision-button-1 fs-13 fw-600"
                >
                  <img class="revision-img-1" :src="require(`../assets/checked-svg.svg`)" />Mark for
                  Clarification
                </button>
              </div>
              <div class="flex flex-column content-cntr">
                <button class="modal-close" @click="closeModal()">
                  <span class="material-icons" style="color: #ffffff !important">close</span>
                </button>
              </div>
            </div>
          </div>
          <div v-if="wrongAnswer" class="correct-section flex content-btw relative">
            <div class="flex" :style="showRevisionModal ? 'filter: blur(2px) !important' : ''">
              <img
                :src="require(`../assets/wrong-answer.svg`)"
                class="center-align correct-image"
              />
              <div class="flex column">
                <span class="fw-700 fs-15 red-text ln-20">Wrong Answer !</span>
                <span class="fw-400 fs-13 blk-text ln-18 wrong-text"
                  >Please review concepts and try again.</span
                >
                <span class="fw-400 fs-13 blk-text ln-18 wrong-text1">Please try again.</span>
              </div>
            </div>
            <div
              class="flex relative"
              :style="showRevisionModal ? 'filter: blur(2px) !important' : ''"
            >
              <hr class="answer-section-hr" style="margin-top: 24px" />
              <button
                v-if="tryAgain === 0"
                class="concept-button fw-600 fs-13 ln-18"
                style="margin-top: 8px"
                @click="showConcept()"
              >
                VIEW CONCEPT
              </button>
              <button
                v-if="tryAgain === 1"
                class="concept-button fw-600 fs-13 ln-18"
                style="margin-top: 8px"
                @click="showSolution()"
              >
                VIEW SOLUTION
              </button>
              <button
                v-if="tryAgain === 0"
                class="concept-button fw-600 fs-13 ln-18"
                style="margin-top: 8px"
                @click="tryAgainQuestion()"
              >
                TRY AGAIN
              </button>
              <button
                v-if="tryAgain === 1"
                class="concept-button-1 fw-600 fs-13 ln-18"
                style="margin-top: 8px"
              >
                TRY AGAIN
              </button>
              <!-- <button class="similar-button fw-600 fs-13 ln-18" style="margin-top: 8px;" @click="similarQuestions()">SIMILAR QUESTION</button> -->
              <button
                class="next-button fw-600 fs-13 ln-18"
                style="margin-top: 8px"
                @click="nextQuestion()"
              >
                NEXT
              </button>
              <button
                class="option-button center-align"
                @click="showOther()"
                style="margin-top: 3px !important; background: #693383"
              >
                <img class="option-img" :src="require(`../assets/threedots.svg`)" />
              </button>
            </div>
            <div v-if="showRevisionModal" class="revision-modal flex content-even">
              <div class="flex flex-column content-even">
                <button
                  v-if="showRevision === false && this.markForRevisionQuestionId === null"
                  class="flex content-left revision-button fs-13 fw-600"
                  @click="markForRevision()"
                >
                  <img class="revision-img" :src="require(`../assets/revise-load.svg`)" />Add to
                  Revision
                </button>
                <button
                  v-if="showRevision === false && this.markForRevisionQuestionId !== null"
                  class="flex content-left revision-button fs-13 fw-600"
                  @click="removeRevision()"
                >
                  <img class="revision-img" :src="require(`../assets/revise-load.svg`)" />Remove
                  from Revision
                </button>
                <button
                  v-if="showRevision === true && this.markForRevisionQuestionId === null"
                  class="flex content-left revision-button-1 fs-13 fw-600"
                >
                  <img class="revision-img-1" :src="require(`../assets/checked-svg.svg`)" />Added to
                  Revision
                </button>
                <button
                  v-if="showRevision === true && this.markForRevisionQuestionId !== null"
                  class="flex content-left revision-button-1 fs-13 fw-600"
                >
                  <img class="revision-img-1" :src="require(`../assets/checked-svg.svg`)" />Removed
                  from Revision
                </button>
                <button
                  v-if="showClarification === false"
                  class="flex content-left revision-button fs-13 fw-600"
                  @click="markQuesForClarification()"
                  :class="{
                    'blue-bg': isMarkForClarification,
                    'white-font': isMarkForClarification,
                  }"
                >
                  <img
                    class="revision-img"
                    :src="require(`../assets/problem-solution.svg`)"
                    :class="{ 'white-bg': isMarkForClarification }"
                  />{{ markTextHandler }} for Clarification
                </button>
                <button
                  v-if="showClarification === true"
                  class="flex content-left revision-button-1 fs-13 fw-600"
                >
                  <img class="revision-img-1" :src="require(`../assets/checked-svg.svg`)" />Mark for
                  Clarification
                </button>
              </div>
              <div class="flex flex-column content-cntr">
                <button class="modal-close" @click="closeModal()">
                  <span class="material-icons" style="color: #ffffff !important">close</span>
                </button>
              </div>
            </div>
          </div>
          <div v-if="showSubjective" class="subjective-section flex content-btw">
            <div :style="showRevisionModal ? 'filter: blur(2px) !important' : ''" class="flex">
              <input
                type="file"
                id="file-upload1"
                style="display: none"
                accept=".jpg, .jpeg, .png"
                multiple
                ref="file"
                @change="onFileLogoChange($event)"
              /><button class="concept-button fw-600 fs-13 ln-18" @click="$refs.file.click()">
                UPLOAD NOW
              </button>
              <button class="similar-button fw-600 fs-13 ln-18" @click="uploadQuestionLater()">
                UPLOAD LATER
              </button>
              <div v-if="fileList.length !== 0" class="flex">
                <hr class="answer-section-hr" style="margin-top: 15px" />
                <button
                  class="image-button fs-12 fw-600 ln-16 flex content-cntr"
                  v-for="(data, index) in fileList"
                  :key="index"
                  :value="data"
                >
                  Image {{ index + 1 }}.{{ data.name.split(".")[1] }}
                </button>
                <img
                  :src="require(`../assets/cross-icon.svg`)"
                  style="height: 20px; width: 20px; margin-top: 8px"
                  @click="removeSolution()"
                />
              </div>
            </div>
            <!-- <div class="progress1" :style="showRevisionModal ? 'filter: blur(2px) !important' : ''">
                  <div class="progress-bar1 red" :style="`width: ${card.gaugeValue*100}%`"  aria-valuemin="0" aria-valuemax="100">
                  <div class="progress-bar1" role="progressbar" :style="`width: 367px`" v-bind:aria-valuenow="parseInt(uploadPercentage)" aria-valuemin="0" aria-valuemax="100" :class="[{ transparent: parseInt(uploadPercentage) == 0 || parseInt(uploadPercentage) == '' }, { green: parseInt(uploadPercentage) <= 100 && parseInt(uploadPercentage) > 0 }]">
                  </div>
                  </div>
                </div> -->
            <div :style="showRevisionModal ? 'filter: blur(2px) !important' : ''" class="flex">
              <!-- <button class="similar-button fw-600 fs-13 ln-18" @click="similarQuestions()">SIMILAR QUESTION</button> -->
              <button v-if="this.fileList.length === 0" class="submit-button fw-600 fs-13 ln-18">
                SUBMIT
              </button>
              <button
                v-if="this.fileList.length !== 0"
                class="submit-button active fw-600 fs-13 ln-18"
                @click="submitSubjectiveQuestionNow()"
              >
                SUBMIT
              </button>
              <button
                class="option-button-1 center-align"
                @click="showOther()"
                style="background: #693383"
              >
                <img class="option-img" :src="require(`../assets/threedots.svg`)" />
              </button>
            </div>
          </div>
          <div v-if="showRevisionModal" class="revision-modal-1 flex content-even">
            <div class="flex flex-column content-even">
              <button
                v-if="showRevision === false && this.markForRevisionQuestionId === null"
                class="flex content-left revision-button fs-13 fw-600"
                @click="markForRevision()"
              >
                <img class="revision-img" :src="require(`../assets/revise-load.svg`)" />Add to
                Revision
              </button>
              <button
                v-if="showRevision === false && this.markForRevisionQuestionId !== null"
                class="flex content-left revision-button fs-13 fw-600"
                @click="removeRevision()"
              >
                <img class="revision-img" :src="require(`../assets/revise-load.svg`)" />Remove from
                Revision
              </button>
              <button
                v-if="showRevision === true && this.markForRevisionQuestionId === null"
                class="flex content-left revision-button-1 fs-13 fw-600"
              >
                <img class="revision-img-1" :src="require(`../assets/checked-svg.svg`)" />Added to
                Revision
              </button>
              <button
                v-if="showRevision === true && this.markForRevisionQuestionId !== null"
                class="flex content-left revision-button-1 fs-13 fw-600"
              >
                <img class="revision-img-1" :src="require(`../assets/checked-svg.svg`)" />Removed
                from Revision
              </button>
              <button
                v-if="showClarification === false"
                class="flex content-left revision-button fs-13 fw-600"
                @click="markQuesForClarification()"
                :class="{ 'blue-bg': isMarkForClarification, 'white-font': isMarkForClarification }"
              >
                <img
                  class="revision-img"
                  :src="require(`../assets/problem-solution.svg`)"
                  :class="{ 'white-bg': isMarkForClarification }"
                />{{ markTextHandler }} for Clarification
              </button>
              <button
                v-if="showClarification === true"
                class="flex content-left revision-button-1 fs-13 fw-600"
              >
                <img class="revision-img-1" :src="require(`../assets/checked-svg.svg`)" />Mark for
                Clarification
              </button>
            </div>
            <div class="flex flex-column content-cntr">
              <button class="modal-close" @click="closeModal()">
                <span class="material-icons" style="color: #ffffff !important">close</span>
              </button>
            </div>
          </div>
          <div v-if="showMultiOption" class="answer-objective flex content-btw">
            <div
              class="flex content-btw"
              style="width: 745px"
              :style="showRevisionModal ? 'filter: blur(2px) !important' : ''"
            >
              <div class="flex">
                <button
                  class="answer-button fw-600 fs-20"
                  :class="{ active: selectedMultiOption.includes('A') }"
                  value="A"
                  @click="checkMultiOption($event)"
                >
                  A
                </button>
                <button
                  class="answer-button fw-600 fs-20"
                  :class="{ active: selectedMultiOption.includes('B') }"
                  value="B"
                  @click="checkMultiOption($event)"
                >
                  B
                </button>
                <button
                  class="answer-button fw-600 fs-20"
                  :class="{ active: selectedMultiOption.includes('C') }"
                  value="C"
                  @click="checkMultiOption($event)"
                >
                  C
                </button>
                <button
                  class="answer-button fw-600 fs-20"
                  :class="{ active: selectedMultiOption.includes('D') }"
                  value="D"
                  @click="checkMultiOption($event)"
                >
                  D
                </button>
              </div>
              <div
                class="flex content-cntr"
                :style="showRevisionModal ? 'filter: blur(2px) !important' : ''"
              >
                <hr class="answer-section-hr" style="margin-top: 24px" />
                <button
                  class="submit-button fw-600 fs-13 ln-18"
                  :class="{ active: selectedMultiOption !== '' }"
                  style="margin-top: 8px"
                  @click="submitMultiAnswer()"
                >
                  SUBMIT
                </button>
                <button
                  class="skip-button fw-600 fs-13 ln-18"
                  style="margin-top: 8px"
                  @click="skipQuestion()"
                >
                  SKIP
                </button>
              </div>
            </div>
            <div style="margin-top: 3px !important">
              <button
                class="option-button center-align"
                @click="showOther()"
                style="background: #693383"
              >
                <img class="option-img" :src="require(`../assets/threedots.svg`)" />
              </button>
            </div>
            <div
              v-if="showRevisionModal"
              class="revision-modal flex content-even"
              style="top: 0 !important; right: 0 !important"
            >
              <div class="flex flex-column content-even">
                <button
                  v-if="showRevision === false && this.markForRevisionQuestionId === null"
                  class="flex content-left revision-button fs-13 fw-600"
                  @click="markForRevision()"
                >
                  <img class="revision-img" :src="require(`../assets/revise-load.svg`)" />Add to
                  Revision
                </button>
                <button
                  v-if="showRevision === false && this.markForRevisionQuestionId !== null"
                  class="flex content-left revision-button fs-13 fw-600"
                  @click="removeRevision()"
                >
                  <img class="revision-img" :src="require(`../assets/revise-load.svg`)" />Remove
                  from Revision
                </button>
                <button
                  v-if="showRevision === true && this.markForRevisionQuestionId === null"
                  class="flex content-left revision-button-1 fs-13 fw-600"
                >
                  <img class="revision-img-1" :src="require(`../assets/checked-svg.svg`)" />Added to
                  Revision
                </button>
                <button
                  v-if="showRevision === true && this.markForRevisionQuestionId !== null"
                  class="flex content-left revision-button-1 fs-13 fw-600"
                >
                  <img class="revision-img-1" :src="require(`../assets/checked-svg.svg`)" />Removed
                  from Revision
                </button>
                <button
                  v-if="showClarification === false"
                  class="flex content-left revision-button fs-13 fw-600"
                  @click="markQuesForClarification()"
                  :class="{
                    'blue-bg': isMarkForClarification,
                    'white-font': isMarkForClarification,
                  }"
                >
                  <img
                    class="revision-img"
                    :src="require(`../assets/problem-solution.svg`)"
                    :class="{ 'white-bg': isMarkForClarification }"
                  />{{ markTextHandler }} for Clarification
                </button>
                <button
                  v-if="showClarification === true"
                  class="flex content-left revision-button-1 fs-13 fw-600"
                >
                  <img class="revision-img-1" :src="require(`../assets/checked-svg.svg`)" />Mark for
                  Clarification
                </button>
              </div>
              <div class="flex flex-column content-cntr">
                <button class="modal-close" @click="closeModal()">
                  <span class="material-icons" style="color: #ffffff !important">close</span>
                </button>
              </div>
            </div>
          </div>
          <div v-if="showNumeric" class="answer-objective flex content-btw">
            <div
              class="flex content-btw"
              style="width: 745px"
              :style="showRevisionModal ? 'filter: blur(2px) !important' : ''"
            >
              <div class="flex number-input" style="max-width: 320px">
                <input
                  class="numeric-field"
                  type="number"
                  placeholder="Enter Number"
                  inputmode="numeric"
                  v-model="numericAnswer"
                />
              </div>
              <div
                class="flex content-cntr"
                :style="showRevisionModal ? 'filter: blur(2px) !important' : ''"
              >
                <hr class="answer-section-hr" style="margin-top: 24px" />
                <button
                  class="submit-button fw-600 fs-13 ln-18"
                  :class="{ active: numericAnswer !== null }"
                  style="margin-top: 8px"
                  @click="submitNumericAnswer()"
                >
                  SUBMIT
                </button>
                <button
                  class="skip-button fw-600 fs-13 ln-18"
                  style="margin-top: 8px"
                  @click="skipQuestion()"
                >
                  SKIP
                </button>
              </div>
            </div>
            <div style="margin-top: 3px !important">
              <button
                class="option-button center-align"
                @click="showOther()"
                style="background: #693383"
              >
                <img class="option-img" :src="require(`../assets/threedots.svg`)" />
              </button>
            </div>
            <div
              v-if="showRevisionModal"
              class="revision-modal flex content-even"
              style="top: 0 !important; right: 0 !important"
            >
              <div class="flex flex-column content-even">
                <button
                  v-if="showRevision === false && this.markForRevisionQuestionId === null"
                  class="flex content-left revision-button fs-13 fw-600"
                  @click="markForRevision()"
                >
                  <img class="revision-img" :src="require(`../assets/revise-load.svg`)" />Add to
                  Revision
                </button>
                <button
                  v-if="showRevision === false && this.markForRevisionQuestionId !== null"
                  class="flex content-left revision-button fs-13 fw-600"
                  @click="removeRevision()"
                >
                  <img class="revision-img" :src="require(`../assets/revise-load.svg`)" />Remove
                  from Revision
                </button>
                <button
                  v-if="showRevision === true && this.markForRevisionQuestionId === null"
                  class="flex content-left revision-button-1 fs-13 fw-600"
                >
                  <img class="revision-img-1" :src="require(`../assets/checked-svg.svg`)" />Added to
                  Revision
                </button>
                <button
                  v-if="showRevision === true && this.markForRevisionQuestionId !== null"
                  class="flex content-left revision-button-1 fs-13 fw-600"
                >
                  <img class="revision-img-1" :src="require(`../assets/checked-svg.svg`)" />Removed
                  from Revision
                </button>
                <button
                  v-if="showClarification === false"
                  class="flex content-left revision-button fs-13 fw-600"
                  @click="markQuesForClarification()"
                  :class="{
                    'blue-bg': isMarkForClarification,
                    'white-font': isMarkForClarification,
                  }"
                >
                  <img
                    class="revision-img"
                    :src="require(`../assets/problem-solution.svg`)"
                    :class="{ 'white-bg': isMarkForClarification }"
                  />{{ markTextHandler }} for Clarification
                </button>
                <button
                  v-if="showClarification === true"
                  class="flex content-left revision-button-1 fs-13 fw-600"
                >
                  <img class="revision-img-1" :src="require(`../assets/checked-svg.svg`)" />Mark for
                  Clarification
                </button>
              </div>
              <div class="flex flex-column content-cntr">
                <button class="modal-close" @click="closeModal()">
                  <span class="material-icons" style="color: #ffffff !important">close</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="conceptDiv === true" style="margin-top: 10px">
        <div class="flex content-btw earn-head" style="background: none !important">
          <div
            class="flex content-btw"
            style=""
            v-if="wrongAnswer === true && correctAnswer === false"
          >
            <span
              class="fw-400 fs-18 concept-head-button"
              :class="{ selected: selectedConcept === index }"
              v-for="(data, index) in kscData"
              :key="index"
              @click="viewConcept(data, index)"
            >
              Concept {{ index + 1 }}</span
            >
            <span
              style="cursor: pointer"
              v-if="tryAgain === 1"
              :class="{ selected: selectedTab === 2 }"
              class="fw-400 fs-18 concept-head-button"
              @click="showFullSolution()"
              >Solution</span
            >
            <span
              style="cursor: pointer"
              v-if="questionData.fullVideoSolutionUrl !== null && tryAgain === 1"
              :class="{ selected: selectedTab === 3 }"
              class="fw-400 fs-18 concept-head-button"
              @click="showVideo()"
              >Video</span
            >
          </div>
          <div
            class="flex content-btw"
            style=""
            v-if="correctAnswer === true && wrongAnswer === false"
          >
            <span
              class="fw-400 fs-18 concept-head-button"
              :class="{ selected: selectedConcept === index }"
              v-for="(data, index) in kscData"
              :key="index"
              @click="viewConcept(data, index)"
            >
              Concept {{ index + 1 }}</span
            >
            <span
              style="cursor: pointer"
              :class="{ selected: selectedTab === 2 }"
              class="fw-400 fs-18 concept-head-button"
              @click="showFullSolution()"
              >Solution</span
            >
            <span
              style="cursor: pointer"
              v-if="questionData.fullVideoSolutionUrl !== null"
              :class="{ selected: selectedTab === 3 }"
              class="fw-400 fs-18 concept-head-button"
              @click="showVideo()"
              >Video</span
            >
          </div>
          <div @click="conceptDiv = false" style="cursor: pointer">
            <span class="material-icons earn-close" style="color: #ffffff !important">close</span>
          </div>
        </div>
        <div style="height: 395px !important">
          <div v-if="selectedConcept !== null" style="margin-top: 10px; margin-left: 15px">
            <img :src="kscDiagram" class="concept-image-2" alt="Concept Image" draggable="false" />
          </div>
          <div v-if="selectedTab == 2" style="margin-top: 10px; margin-left: 15px">
            <img
              :src="questionData.fullSolutionURL"
              class="full-solution-image-2"
              alt="Solution Image"
              draggable="false"
            />
          </div>
          <div v-if="selectedTab == 3" style="margin-top: 10px; margin-left: 15px">
            <iframe :src="questionData.fullVideoSolutionUrl" style="width: max-content"></iframe>
          </div>
        </div>
        <div class="answer-section-1">
          <div v-if="correctAnswer" class="correct-section flex content-btw relative">
            <div class="flex" :style="showRevisionModal ? 'filter: blur(2px) !important' : ''">
              <img
                :src="require(`../assets/correct-answer.svg`)"
                class="center-align correct-image"
              />
              <div class="flex column">
                <span class="fw-700 fs-15 blue-text ln-20">Correct Answer !</span>
                <span class="fw-400 fs-13 blk-text ln-18"
                  ><b>{{ selectedQuestionData.Accuracy }}</b> solved this question correctly in
                  <b>{{ selectedQuestionData.AvgTime }}</b></span
                >
              </div>
            </div>
            <div
              class="flex relative"
              :style="showRevisionModal ? 'filter: blur(2px) !important' : ''"
            >
              <hr class="answer-section-hr" style="margin-top: 24px" />
              <button
                class="concept-button fw-600 fs-13 ln-18"
                style="margin-top: 8px"
                @click="showConcept()"
              >
                VIEW SOLUTION
              </button>
              <!-- <button class="similar-button fw-600 fs-13 ln-18" style="margin-top: 8px;" @click="similarQuestions()">SIMILAR QUESTION</button> -->
              <button
                class="next-button fw-600 fs-13 ln-18"
                style="margin-top: 8px"
                @click="nextQuestion()"
              >
                NEXT
              </button>
              <button
                class="option-button center-align"
                @click="showOther()"
                style="margin-top: 3px !important; background: #693383"
              >
                <img class="option-img" :src="require(`../assets/threedots.svg`)" />
              </button>
            </div>
            <div v-if="showRevisionModal" class="revision-modal flex content-even">
              <div class="flex flex-column content-even">
                <button
                  v-if="showRevision === false && this.markForRevisionQuestionId === null"
                  class="flex content-left revision-button fs-13 fw-600"
                  @click="markForRevision()"
                >
                  <img class="revision-img" :src="require(`../assets/revise-load.svg`)" />Add to
                  Revision
                </button>
                <button
                  v-if="showRevision === false && this.markForRevisionQuestionId !== null"
                  class="flex content-left revision-button fs-13 fw-600"
                  @click="removeRevision()"
                >
                  <img class="revision-img" :src="require(`../assets/revise-load.svg`)" />Remove
                  from Revision
                </button>
                <button
                  v-if="showRevision === true && this.markForRevisionQuestionId === null"
                  class="flex content-left revision-button-1 fs-13 fw-600"
                >
                  <img class="revision-img-1" :src="require(`../assets/checked-svg.svg`)" />Added to
                  Revision
                </button>
                <button
                  v-if="showRevision === true && this.markForRevisionQuestionId !== null"
                  class="flex content-left revision-button-1 fs-13 fw-600"
                >
                  <img class="revision-img-1" :src="require(`../assets/checked-svg.svg`)" />Removed
                  from Revision
                </button>
                <button
                  v-if="showClarification === false"
                  class="flex content-left revision-button fs-13 fw-600"
                  @click="markQuesForClarification()"
                  :class="{
                    'blue-bg': isMarkForClarification,
                    'white-font': isMarkForClarification,
                  }"
                >
                  <img
                    class="revision-img"
                    :src="require(`../assets/problem-solution.svg`)"
                    :class="{ 'white-bg': isMarkForClarification }"
                  />{{ markTextHandler }} for Clarification
                </button>
                <button
                  v-if="showClarification === true"
                  class="flex content-left revision-button-1 fs-13 fw-600"
                >
                  <img class="revision-img-1" :src="require(`../assets/checked-svg.svg`)" />Mark for
                  Clarification
                </button>
              </div>
              <div class="flex flex-column content-cntr">
                <button class="modal-close" @click="closeModal()">
                  <span class="material-icons" style="color: #ffffff !important">close</span>
                </button>
              </div>
            </div>
          </div>
          <div v-if="wrongAnswer" class="correct-section flex content-btw relative">
            <div class="flex" :style="showRevisionModal ? 'filter: blur(2px) !important' : ''">
              <img
                :src="require(`../assets/wrong-answer.svg`)"
                class="center-align correct-image"
              />
              <div class="flex column">
                <span class="fw-700 fs-15 red-text ln-20">Wrong Answer !</span>
                <span class="fw-400 fs-13 blk-text ln-18 wrong-text"
                  >Please review concepts and try again.</span
                >
                <span class="fw-400 fs-13 blk-text ln-18 wrong-text1">Please try again.</span>
              </div>
            </div>
            <div
              class="flex relative"
              :style="showRevisionModal ? 'filter: blur(2px) !important' : ''"
            >
              <hr class="answer-section-hr" style="margin-top: 24px" />
              <button
                v-if="tryAgain === 0"
                class="concept-button fw-600 fs-13 ln-18"
                style="margin-top: 8px"
                @click="showConcept()"
              >
                VIEW CONCEPT
              </button>
              <button
                v-if="tryAgain === 1"
                class="concept-button fw-600 fs-13 ln-18"
                style="margin-top: 8px"
                @click="showSolution()"
              >
                VIEW SOLUTION
              </button>
              <button
                v-if="tryAgain === 0"
                class="concept-button fw-600 fs-13 ln-18"
                style="margin-top: 8px"
                @click="tryAgainQuestion()"
              >
                TRY AGAIN
              </button>
              <button
                v-if="tryAgain === 1"
                class="concept-button-1 fw-600 fs-13 ln-18"
                style="margin-top: 8px"
              >
                TRY AGAIN
              </button>
              <!-- <button class="similar-button fw-600 fs-13 ln-18" style="margin-top: 8px;" @click="similarQuestions()">SIMILAR QUESTION</button> -->
              <button
                class="next-button fw-600 fs-13 ln-18"
                style="margin-top: 8px"
                @click="nextQuestion()"
              >
                NEXT
              </button>
              <button
                class="option-button center-align"
                @click="showOther()"
                style="margin-top: 3px !important; background: #693383"
              >
                <img class="option-img" :src="require(`../assets/threedots.svg`)" />
              </button>
            </div>
            <div v-if="showRevisionModal" class="revision-modal flex content-even">
              <div class="flex flex-column content-even">
                <button
                  v-if="showRevision === false && this.markForRevisionQuestionId === null"
                  class="flex content-left revision-button fs-13 fw-600"
                  @click="markForRevision()"
                >
                  <img class="revision-img" :src="require(`../assets/revise-load.svg`)" />Add to
                  Revision
                </button>
                <button
                  v-if="showRevision === false && this.markForRevisionQuestionId !== null"
                  class="flex content-left revision-button fs-13 fw-600"
                  @click="removeRevision()"
                >
                  <img class="revision-img" :src="require(`../assets/revise-load.svg`)" />Remove
                  from Revision
                </button>
                <button
                  v-if="showRevision === true && this.markForRevisionQuestionId === null"
                  class="flex content-left revision-button-1 fs-13 fw-600"
                >
                  <img class="revision-img-1" :src="require(`../assets/checked-svg.svg`)" />Added to
                  Revision
                </button>
                <button
                  v-if="showRevision === true && this.markForRevisionQuestionId !== null"
                  class="flex content-left revision-button-1 fs-13 fw-600"
                >
                  <img class="revision-img-1" :src="require(`../assets/checked-svg.svg`)" />Removed
                  from Revision
                </button>
                <button
                  v-if="showClarification === false"
                  class="flex content-left revision-button fs-13 fw-600"
                  @click="markQuesForClarification()"
                  :class="{
                    'blue-bg': isMarkForClarification,
                    'white-font': isMarkForClarification,
                  }"
                >
                  <img
                    class="revision-img"
                    :src="require(`../assets/problem-solution.svg`)"
                    :class="{ 'white-bg': isMarkForClarification }"
                  />{{ markTextHandler }} for Clarification
                </button>
                <button
                  v-if="showClarification === true"
                  class="flex content-left revision-button-1 fs-13 fw-600"
                >
                  <img class="revision-img-1" :src="require(`../assets/checked-svg.svg`)" />Mark for
                  Clarification
                </button>
              </div>
              <div class="flex flex-column content-cntr">
                <button class="modal-close" @click="closeModal()">
                  <span class="material-icons" style="color: #ffffff !important">close</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="prompt-toast" class="promptBox">
        <Prompts :isPromptVisible="promptVisible" :selectedPromptOption="promptNumber" />
      </div>
    </div>
    <div class="portrait">
      <div class="valign-wrapper">
        <div class="valign">
          <div style="margin-bottom: 100px">
            <div class="rotate"></div>
            <h5 class="light">Please rotate your device</h5>
            <span
              >We don't support portrat mode yet. Rotate your device to landscape mode for the best
              experience</span
            >
          </div>
        </div>
      </div>
    </div>
    <Modal3
      :show="conceptModal"
      :showHeader="false"
      :showCloseBtn="false"
      @close="conceptModal = false"
    >
      <template v-slot:body style="max-height: 660px !important">
        <div class="concept-modal">
          <div class="flex content-btw earn-head">
            <div
              class="flex content-btw"
              style=""
              v-if="wrongAnswer === true && correctAnswer === false"
            >
              <span
                class="fw-400 fs-18 concept-head-button"
                :class="{ selected: selectedConcept === index }"
                v-for="(data, index) in kscData"
                :key="index"
                @click="viewConcept(data, index)"
              >
                Concept {{ index + 1 }}</span
              >
              <span
                style="cursor: pointer"
                v-if="tryAgain === 1"
                :class="{ selected: selectedTab === 2 }"
                class="fw-400 fs-18 concept-head-button"
                @click="showFullSolution()"
                >Solution</span
              >
              <span
                style="cursor: pointer"
                v-if="questionData.fullVideoSolutionUrl !== null && tryAgain === 1"
                :class="{ selected: selectedTab === 3 }"
                class="fw-400 fs-18 concept-head-button"
                @click="showVideo()"
                >Video</span
              >
            </div>
            <div
              class="flex content-btw"
              style=""
              v-if="correctAnswer === true && wrongAnswer === false"
            >
              <span
                class="fw-400 fs-18 concept-head-button"
                :class="{ selected: selectedConcept === index }"
                v-for="(data, index) in kscData"
                :key="index"
                @click="viewConcept(data, index)"
              >
                Concept {{ index + 1 }}</span
              >
              <span
                style="cursor: pointer"
                :class="{ selected: selectedTab === 2 }"
                class="fw-400 fs-18 concept-head-button"
                @click="showFullSolution()"
                >Solution</span
              >
              <span
                style="cursor: pointer"
                v-if="questionData.fullVideoSolutionUrl !== null"
                :class="{ selected: selectedTab === 3 }"
                class="fw-400 fs-18 concept-head-button"
                @click="showVideo()"
                >Video</span
              >
            </div>
            <div @click="conceptModal = false" style="cursor: pointer">
              <span class="material-icons earn-close" style="color: #ffffff !important">close</span>
            </div>
          </div>
          <div v-if="selectedConcept !== null">
            <img :src="kscDiagram" class="concept-image" alt="Concept Image" draggable="false" />
          </div>
          <div v-if="selectedTab == 2">
            <img
              :src="questionData.fullSolutionURL"
              class="full-solution-image"
              alt="Solution Image"
              draggable="false"
            />
          </div>
          <div v-if="selectedTab == 3">
            <iframe
              :src="questionData.fullVideoSolutionUrl"
              style="width: 100%; height: 500px"
            ></iframe>
          </div>
        </div>
      </template>
    </Modal3>
    <Modal2 :show="endModal" :showHeader="false" :showCloseBtn="false" @close="endModal = false">
      <template v-slot:body>
        <div class="end-modal">
          <div class="flex content-cntr" style="margin-bottom: 20px; padding-top: 30px">
            <img class="" :src="require(`../assets/end-session.svg`)" />
          </div>
          <span class="flex content-cntr fs-20 fw-600 ln-27" style="margin-bottom: 8px"
            >End Practice Session</span
          >
          <div style="margin-bottom: 25px">
            <span class="flex content-cntr fs-13 fw-400 ln-17"
              >Are you sure you want to end this session?</span
            >
            <span class="flex content-cntr fs-13 fw-400 ln-17"
              >You will not be able to resume this session again.</span
            >
          </div>
          <div class="flex content-even">
            <button class="end-cancel-button fs-13 fw-600 ln-18" @click="cancelEndModal()">
              CANCEL
            </button>
            <button class="end-confirm-button fs-13 fw-600 ln-18" @click="endPracticeSession()">
              END NOW
            </button>
          </div>
        </div>
      </template>
    </Modal2>
    <Modal2
      :show="pauseModal"
      :showHeader="false"
      :showCloseBtn="false"
      @close="pauseModal = false"
    >
      <template v-slot:body>
        <div class="end-modal">
          <div class="flex content-cntr" style="margin-bottom: 20px; padding-top: 30px">
            <img class="" :src="require(`../assets/pausesvg.svg`)" />
          </div>
          <span class="flex content-cntr fs-20 fw-600 ln-27" style="margin-bottom: 8px"
            >Pause
          </span>
          <div style="margin-bottom: 25px">
            <span class="flex content-cntr fs-13 fw-400 ln-17"
              >Are you sure you want to pause this session?</span
            >
            <span class="flex content-cntr fs-13 fw-400 ln-17"
              >You will be able to resume this session again.</span
            >
          </div>
          <div class="flex content-even">
            <button class="end-cancel-button fs-13 fw-600 ln-18" @click="cancelEndModal()">
              CANCEL
            </button>
            <button class="end-confirm-button fs-13 fw-600 ln-18" @click="pauseSession()">
              PAUSE
            </button>
          </div>
        </div>
      </template>
    </Modal2>
    <Modal2
      :show="reportModal"
      :showHeader="false"
      :showCloseBtn="false"
      @close="reportModal = false"
    >
      <template v-slot:body>
        <div class="report-modal">
          <div class="flex content-cntr" style="margin-bottom: 10px; padding-top: 20px">
            <img class="" :src="require(`../assets/errorsvg.svg`)" />
          </div>
          <span class="flex content-cntr fs-20 fw-700 ln-27" style="margin-bottom: 8px"
            >Report Error</span
          >
          <hr class="report-line" style="border: 1px solid #e0e4f0" />
          <div style="margin-top: 20px; margin-left: 23px">
            <span class="fs-20 fw-600 ln-17">What went wrong with this question?</span>
            <div class="flex flex-column">
              <label class="report-label"
                ><input
                  type="radio"
                  class="with-gap"
                  id="ksc"
                  value="1"
                  v-model="reportErrorValue"
                /><span class="fw-400 fs-15 blk-text" for="ksc">KSC not helpful</span></label
              >
              <label class="report-label"
                ><input
                  type="radio"
                  class="with-gap"
                  id="ksc"
                  value="2"
                  v-model="reportErrorValue"
                /><span class="fw-400 fs-15 blk-text" for="ksc">Answer tagging wrong</span></label
              >
              <label class="report-label"
                ><input
                  type="radio"
                  class="with-gap"
                  id="ksc"
                  value="3"
                  v-model="reportErrorValue"
                /><span class="fw-400 fs-15 blk-text" for="ksc">Mistake in Q and A</span></label
              >
              <label class="report-label"
                ><input
                  type="radio"
                  class="with-gap"
                  id="ksc"
                  value="5"
                  v-model="reportErrorValue"
                /><span class="fw-400 fs-15 blk-text" for="ksc">Unclear image</span></label
              >
              <label class="report-label"
                ><input
                  type="radio"
                  class="with-gap"
                  id="ksc"
                  value="6"
                  v-model="reportErrorValue"
                /><span class="fw-400 fs-15 blk-text" for="ksc">Teacher feedback</span></label
              >
              <label class="report-label"
                ><input
                  type="radio"
                  class="with-gap"
                  id="ksc"
                  value="4"
                  v-model="reportErrorValue"
                /><span class="fw-400 fs-15 blk-text" for="ksc">Other errors</span></label
              >
            </div>
            <div class="flex content-btw" style="width: 264px !important; margin-top: 30px">
              <button class="end-cancel-button fs-13 fw-600 ln-18" @click="cancelEndModal()">
                CANCEL
              </button>
              <button class="end-confirm-button fs-13 fw-600 ln-18" @click="submitErrorReport()">
                SUBMIT
              </button>
            </div>
          </div>
        </div>
      </template>
    </Modal2>
    <Modal2 :show="appModal" :showHeader="false" :showCloseBtn="false" @close="appModal = false">
      <template v-slot:body> </template>
    </Modal2>
    <Modal2 :show="showQueEndModal" :showHeader="false" :showCloseBtn="false">
      <template v-slot:body>
        <div class="no-ques-modal" style="height: 300px; width: 500px">
          <div class="flex content-cntr" style="margin-bottom: 20px; padding-top: 30px">
            <img class="" :src="require(`../assets/errorsvg.svg`)" />
          </div>
          <span class="flex content-cntr fs-20 fw-600 ln-27" style="margin-bottom: 8px"
            >Congratuations! You have done all the questions.</span
          >
          <div class="flex content-cntr">
            <!-- <button class="end-cancel-button fs-13 fw-600 ln-18" @click="cancelEndModal()">Continue</button> -->
            <button class="end-confirm-button fs-13 fw-600 ln-18" @click="endPracticeSession()">
              End Session
            </button>
          </div>
        </div>
        <!-- <div style="text-align: center; padding: 10px;">
                <h3 style="font-size: 25px;">Congratuations! You have done all the questions.</h3>
                <button class="end-confirm-button fs-13 fw-600 ln-18" @click="endPracticeSession()" style="margin-top: 15px;">END Session</button>
            </div> -->
      </template>
    </Modal2>
    <Modal2 :show="noHardQuestion" :showHeader="false" :showCloseBtn="false">
      <template v-slot:body>
        <div class="no-ques-modal">
          <div class="flex content-cntr" style="margin-bottom: 20px; padding-top: 30px">
            <img class="" :src="require(`../assets/errorsvg.svg`)" />
          </div>
          <span class="flex content-cntr fs-20 fw-600 ln-27" style="margin-bottom: 8px"
            >No More Hard Questions Left</span
          >
          <div style="margin-bottom: 25px">
            <span class="flex content-cntr fs-13 fw-400 ln-17"
              >It looks like you have already attempted all the tougher questions accurately.</span
            >
            <span class="flex content-cntr fs-13 fw-400 ln-17"
              >If you continue the session (not recommended), you will get easier questions
              now.</span
            >
          </div>
          <div class="flex content-even">
            <button class="end-cancel-button fs-13 fw-600 ln-18" @click="cancelEndModal()">
              Continue
            </button>
            <button class="end-confirm-button fs-13 fw-600 ln-18" @click="endPracticeSession()">
              End Session
            </button>
          </div>
        </div>
      </template>
    </Modal2>
    <Modal2 :show="noEasyQuestion" :showHeader="false" :showCloseBtn="false">
      <template v-slot:body>
        <div class="no-ques-modal">
          <div class="flex content-cntr" style="margin-bottom: 20px; padding-top: 30px">
            <img class="" :src="require(`../assets/errorsvg.svg`)" />
          </div>
          <span class="flex content-cntr fs-20 fw-600 ln-27" style="margin-bottom: 8px"
            >No More Easy Questions Left</span
          >
          <div style="margin-bottom: 25px">
            <span class="flex content-cntr fs-13 fw-400 ln-17"
              >It looks like your accuracy is low and you have already attempted all
            </span>
            <span class="flex content-cntr fs-13 fw-400 ln-17"
              >the easy questions. If you continue the session (not recommended),
            </span>
            <span class="flex content-cntr fs-13 fw-400 ln-17"
              >you will get more difficult questions.</span
            >
          </div>
          <div class="flex content-even">
            <button class="end-cancel-button fs-13 fw-600 ln-18" @click="cancelEndModal()">
              Continue
            </button>
            <button class="end-confirm-button fs-13 fw-600 ln-18" @click="endPracticeSession()">
              End Session
            </button>
          </div>
        </div>
      </template>
    </Modal2>
    <Modal2 :show="allAttempted" :showHeader="false" :showCloseBtn="false">
      <template v-slot:body>
        <div class="no-ques-modal">
          <div class="flex content-cntr" style="margin-bottom: 20px; padding-top: 30px">
            <img class="" :src="require(`../assets/errorsvg.svg`)" />
          </div>
          <span class="flex content-cntr fs-20 fw-600 ln-27" style="margin-bottom: 8px"
            >All questions attempted</span
          >
          <div style="margin-bottom: 25px">
            <span class="flex content-cntr fs-13 fw-400 ln-17"
              >You have attempted all available questions in this session.</span
            >
            <span class="flex content-cntr fs-13 fw-400 ln-17"
              >Please select a different subject / chapter to continue practicing</span
            >
          </div>
          <div class="flex content-even">
            <!-- <button class="end-cancel-button fs-13 fw-600 ln-18" @click="cancelEndModal()">Continue</button> -->
            <button class="end-confirm-button fs-13 fw-600 ln-18" @click="endPracticeSession()">
              End Session
            </button>
          </div>
        </div>
      </template>
    </Modal2>
    <Modal2 :show="noMoreNewQuestion" :showHeader="false" :showCloseBtn="false">
      <template v-slot:body>
        <div class="no-ques-modal">
          <div class="flex content-cntr" style="margin-bottom: 20px; padding-top: 30px">
            <img class="" :src="require(`../assets/errorsvg.svg`)" />
          </div>
          <span class="flex content-cntr fs-20 fw-600 ln-27" style="margin-bottom: 8px"
            >No More New Questions Left</span
          >
          <div style="margin-bottom: 25px">
            <span class="flex content-cntr fs-13 fw-400 ln-17"
              >It seems that you have practiced all available questions.If you continue this
              session</span
            >
            <span class="flex content-cntr fs-13 fw-400 ln-17"
              >(not recommended), you will probably get previously seen questions again.</span
            >
            <span class="flex content-cntr fs-13 fw-400 ln-17"> Do you want to continue?</span>
          </div>
          <div class="flex content-even">
            <button class="end-cancel-button fs-13 fw-600 ln-18" @click="cancelEndModal()">
              Continue
            </button>
            <button class="end-confirm-button fs-13 fw-600 ln-18" @click="endPracticeSession()">
              End Session
            </button>
          </div>
        </div>
      </template>
    </Modal2>
    <Modal2 :show="refreshTimeModal" :showHeader="false" :showCloseBtn="false">
      <template v-slot:body>
        <div class="no-ques-modal">
          <div class="flex content-cntr" style="margin-bottom: 20px; padding-top: 30px">
            <img class="" :src="require(`../assets/errorsvg.svg`)" />
          </div>
          <span class="flex content-cntr fs-20 fw-600 ln-27" style="margin-bottom: 8px"
            >Refresh Practice Session</span
          >
          <div style="margin-bottom: 25px">
            <span class="flex content-cntr fs-13 fw-400 ln-17"
              >Pause practice session to dave your progress</span
            >
            <!-- <span class="flex content-cntr fs-13 fw-400 ln-17">Please select a different subject / chapter to continue practicing</span> -->
          </div>
          <div class="flex content-even">
            <!-- <button class="end-cancel-button fs-13 fw-600 ln-18" @click="cancelEndModal()">Continue</button> -->
            <button class="end-confirm-button fs-13 fw-600 ln-18" @click="pauseSession()">
              Pause Session
            </button>
          </div>
        </div>
      </template>
    </Modal2>
  </div>
</template>

<script>
// import Vue from "vue";
import moment from "moment";
// import vClickOutside from "v-click-outside";
import Prompts from "../components/UserPrompt.vue";
import Doubtapi from "../Doubtapi";
import MobileAPI from "../Mobileapi";
import DoughnutChart from "../components/charts/DoughnutChart.vue";
import Modal2 from "../components/Model2.vue";
import Modal3 from "../components/Modal3.vue";
// import questionGroup from "../../practiceSessionQuesList.json";

// Vue.use(vClickOutside);

export default {
  data() {
    return {
      selectedOption: "",
      correctAnswer: false,
      wrongAnswer: false,
      showOptions: false,
      showRevisionModal: false,
      className: "",
      subjectName: "",
      practiceLevel: null,
      chapterName: "",
      courseId: null,
      subjectId: null,
      userTestSessionId: null,
      testTypeId: null,
      slToken: "",
      userDetails: [],
      questionImage: "",
      questionAnswer: "",
      questionId: null,
      userTestSessionQuestionId: null,
      questionData: [],
      kscData: [],
      conceptModal: false,
      conceptDiv: false,
      selectedTab: 1,
      kscDiagram: "",
      selectedConcept: null,
      questionScore: null,
      showRevision: false,
      showClarification: false,
      tryAgain: 0,
      endModal: false,
      showSubjective: false,
      showNumeric: false,
      showMultiOption: false,
      showAssertion: false,
      uploadPercentage: 10,
      fileList: [],
      imageList: [],
      images: [],
      subjectiveQuestionList: [],
      selectedMultiOption: "",
      numericAnswer: null,
      reportModal: false,
      reportErrorValue: "",
      pauseModal: false,
      firstAttempt: true,
      answerGiven: "",
      subjectIconURL: "",
      subBgColor: "",
      answerNumeric: null,
      showScore: false,
      pieChartData: {
        datasets: [
          {
            cutout: "65%",
            label: "My First Dataset",
            data: [10, 10, 10],
            value: null,
            backgroundColor: [
              "rgba(0, 184, 148, 1)",
              "rgba(255, 118, 117, 1)",
              "rgba(253, 203, 110, 1)",
            ],
            hoverOffset: 4,
          },
        ],
      },
      pieChartOptions: {
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            callbacks: {
              label(tooltipItem) {
                return ` ${tooltipItem.label}: ${tooltipItem.raw}s`;
              },
            },
          },
          options: {
            layout: {
              thickness: 20,
            },
          },
        },
      },
      appModal: false,
      windowWidth: window.innerWidth,
      // new code
      quesListLength: null,
      QuestionGroup: null,
      queGrpList: [],
      currentGrp: [],
      nextGrp: [],
      currQueIndex: null,
      tempCurrQueIndex: null,
      selectedGrpObj: null,
      currentQuestionId: null,
      netGrpScore: 0,
      tempNetGrpScore: 0,
      tempCorrScore: 0,
      isAnsCorrect: false,
      isGrpIterate: false,
      showQueEndModal: false,
      timeTaken: 0,
      timer: 0,
      currentQnIndex: 0,
      curentGroupArrayIndex: 0,
      currentQnGroup: [],
      questionListArray: [],
      firstTime: 1,
      nextQuestionData: [],
      attempted: 0,
      viewed: 0,
      isCorrect: 0,
      selectedQuestionData: [],
      practiceSessionScore: [],
      resumeSessionData: [],
      kscList: [],
      chapterList: [],
      subjectList: [],
      message: [],
      prompts: [],
      isMarkForRevision: false,
      isMarkForClarification: false,
      isSkipped: false,
      noHardQuestion: false,
      noEasyQuestion: false,
      allAttempted: false,
      refreshTimeModal: false,
      practiceSessionData: [],
      markForRevisionQuestionId: null,
      easyQuestionList: [],
      hardQuestionList: [],
      totalQuestionList: [],
      newQuestionList: [],
      newQuestionCount: 0,
      totalQuestionCount: 0,
      easyQuestionCount: 0,
      hardQuestionCount: 0,
      noMoreNewQuestion: false,
      newSortedQuestionArray: [],
      checkQuestion: false,
      showNoEasy: 0,
      showNoHard: 0,
      showNoNew: 0,
      promptsQuestion: [],
      promptsVariables: {
        last10Correct: 0,
        last20Correct: 0,
        last10Wrong: 0,
        last20Wrong: 0,
        prevKscId: 0,
        last5Skip: 0,
        totalCorrect: 0,
        totalAttempt: 0,
        last5Attempted: 0,
        totalTime: 0,
        avgTime: 0,
      },
      promptNumber: null,
      promptVisible: false,
      isEasyMiss: 0,
      isSubjective: null,
      subjectiveQuestionIndex: null,
      attemptedQuestionCount: 0,
      viewedQuestionCount: 0,
      attemptMedian: 0,
      averageTime: 0,
      subjectAccuracy: 0,
      shownPrompts: [],
      isMobileDevice: false,
      isLps: null,
      lpsId: null,
      lpsEndTime: null,
      questionLatexStatus: "",
      questionLatex: "",
      questionDiagram: "",
      questionPosition: "",
      questionLatexDiagram: "",
      questionOptionA: "",
      questionOptionB: "",
      questionOptionC: "",
      questionOptionD: "",
      VueMathJaxOptions: {
        extensions: ["tex2jax.js"],
        showProcessingMessages: false,
        jax: ["input/TeX", "output/chtml"],
        lazyAlwaysTypeset: null,
        showMathMenu: false,
        tex2jax: {
          inlineMath: [
            ["$", "$"],
            ["\\(", "\\)"],
          ],
          displayMath: [
            ["$$", "$$"],
            ["\\[", "\\]"],
          ],
          processEscapes: false,
          processClass: "tex2jax_process",
        },
      },
      pastYearQuestionIndex: 0,
      isPastYear: null,
      isDppSession: null,
    };
  },
  unmounted() {
    localStorage.removeItem("IsLps");
    localStorage.removeItem("LpsId");
    localStorage.removeItem("LpsEndTime");
  },
  activated() {
    // this.noEasyQuestion = true;
    this.isLps = parseInt(this.$route.params.isLps);
    this.lpsId = Number(this.$route.params.LpsId);
    this.lpsEndTime = this.$route.params.LpsEndTime;
    this.pastYearQuestionIndex = 0;
    if (this.lpsId) {
      this.lpsId = Number(this.$route.params.LpsId);
      localStorage.setItem("LpsId", this.$route.params.LpsId);
    } else {
      this.lpsId = Number(localStorage.getItem("LpsId"));
    }
    if (this.isLps) {
      this.isLps = parseInt(this.$route.params.isLps);
      localStorage.setItem("IsLps", this.$route.params.isLps);
    } else {
      this.isLps = parseInt(localStorage.getItem("IsLps"));
    }
    if (this.lpsEndTime) {
      this.lpsEndTime = parseInt(this.$route.params.LpsEndTime);
      localStorage.setItem("LpsEndTime", this.$route.params.LpsEndTime);
    } else {
      this.lpsEndTime = parseInt(localStorage.getItem("LpsEndTime"));
    }
    if (localStorage.getItem("LpsEndTime")) {
      setInterval(() => {
        this.compareDateTime();
      }, 1000);
    }
    // console.log(this.isLps);
    this.selectedQuestionData = [];
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
    ) {
      this.isMobileDevice = true;
    } else {
      this.isMobileDevice = false;
    }
    this.timeTaken = 0;
    this.correctAnswer = false;
    this.wrongAnswer = false;
    this.userDetails = this.$store.getters.user;
    if (localStorage && localStorage.getItem("SLToken")) {
      this.slToken = localStorage.getItem("SLToken") || "";
    }
    if (
      localStorage.getItem("isPastYear") === undefined ||
      localStorage.getItem("isPastYear") === null
    ) {
      this.isPastYear = Number(this.$route.params.isPastYear);
      localStorage.setItem("isPastYear", Number(this.isPastYear));
    } else {
      this.isPastYear = Number(localStorage.getItem("isPastYear"));
    }
    if (this.$route.params.isDppSession !== undefined) {
      localStorage.setItem("isDppSession", this.$route.params.isDppSession);
      this.isDppSession = Number(this.$route.params.isDppSession);
    } else {
      this.isDppSession = Number(localStorage.getItem("isDppSession"));
    }
    if (this.isPastYear === 0) {
      if (
        this.$route.params.questionList !== undefined &&
        Number(this.$route.params.isSubjective) === 1
      ) {
        localStorage.setItem("isSubjective", Number(this.$route.params.isSubjective));
        this.isSubjective = Number(this.$route.params.isSubjective);
        this.subjectiveQuestionIndex = 0;
        this.questionList = JSON.parse(this.$route.params.questionList);
        this.kscList = JSON.parse(this.$route.params.kscList);
        this.chapterList = JSON.parse(this.$route.params.chapterList);
        this.subjectList = JSON.parse(this.$route.params.subjectList);
        this.userTestSessionId = Number(this.$route.params.UserTestSessionId);
        this.className = this.$route.params.className;
        this.subjectName = this.$route.params.subjectName;
        this.practiceLevel = this.$route.params.PracticeLevel;
        this.courseId = Number(this.$route.params.coureId);
        this.subjectId = Number(this.$route.params.subjectId);
        this.getSubjectDetails(this.subjectId);
        this.queGrpList = this.questionList;
        this.nextSubjectiveQuestion(this.queGrpList);
        this.practiceNextNewQuestion();
      }
      if (
        this.$route.params.questionList !== undefined &&
        Number(this.$route.params.isSubjective) === 0
      ) {
        localStorage.setItem("isSubjective", Number(this.$route.params.isSubjective));
        this.questionList = JSON.parse(this.$route.params.questionList);
        this.kscList = JSON.parse(this.$route.params.kscList);
        this.chapterList = JSON.parse(this.$route.params.chapterList);
        this.subjectList = JSON.parse(this.$route.params.subjectList);
        this.attemptMedian = Number(this.subjectList.globalStats.AttemptedMedian);
        this.averageTime = Number(this.subjectList.userSubject.AvgTime);
        this.subjectAccuracy = this.subjectList.userSubject.Accuracy.replace("%", "");
        this.subjectAccuracy = Number(this.subjectAccuracy) / 100;
        this.queGrpList = this.questionList;
        this.firstTime = 1;
        if (this.firstTime === 1) {
          const initialQuestion = 1;
          this.newQuestionSelectionFunction(this.queGrpList, initialQuestion);
        }
        this.className = this.$route.params.className;
        this.subjectName = this.$route.params.subjectName;
        this.practiceLevel = this.$route.params.PracticeLevel;
        this.courseId = Number(this.$route.params.coureId);
        this.subjectId = Number(this.$route.params.subjectId);
        this.userTestSessionId = Number(this.$route.params.UserTestSessionId);
        this.testTypeId = Number(this.$route.params.QuestionTypeId);
        this.getSubjectDetails(this.subjectId);
        this.practiceNextNewQuestion();
      } else if (
        this.$route.params.questionList === undefined &&
        this.$route.params.UserTestSessionId !== undefined
      ) {
        localStorage.setItem("isSubjective", Number(0));
        this.practiceId = Number(this.$route.params.UserTestSessionId);
        this.userTestSessionId = Number(this.$route.params.UserTestSessionId);
        const data = {
          lpsId: this.lpsId ? this.lpsId : 0,
        };
        MobileAPI.resumePracticeSession(this.practiceId, data, (response) => {
          this.resumeSessionData = response.data;
          this.firstTime = 0;
          if (this.resumeSessionData !== null) {
            this.questionList = this.resumeSessionData.practiceQuestionsList;
            this.curentGroupArrayIndex = this.resumeSessionData.currentQuestionGroupIndex;
            this.currQueIndex = this.resumeSessionData.currentQuestionIndex;
            this.practiceSessionScore = this.resumeSessionData.practiceScoreList;
            this.queGrpList = this.questionList;
            const isResumeSession = 1;
            this.subjectId = Number(this.$route.params.subjectId);
            this.courseId = Number(this.$route.params.coureId);
            this.className = this.$route.params.className;
            this.subjectName = this.$route.params.subjectName;
            this.kscList = this.resumeSessionData.kscList;
            this.chapterList = this.resumeSessionData.chapterList;
            this.subjectList = this.resumeSessionData.subjectList;
            this.attemptMedian = this.subjectList.globalStats.AttemptedMedian;
            this.averageTime = this.subjectList.userSubject.AvgTime;
            this.subjectAccuracy = this.subjectList.userSubject.Accuracy.replace("%", "");
            this.subjectAccuracy = Number(this.subjectAccuracy);
            this.promptsQuestion = this.resumeSessionData.promptQuestionData;
            this.promptsVariables = this.resumeSessionData.practicePromptsData;
            this.shownPrompts.push(this.resumeSessionData.shownPromptsList.split(","));
            const initialQuestion = null;
            this.newQuestionSelectionFunction(
              this.queGrpList,
              initialQuestion,
              isResumeSession,
              this.practiceSessionScore,
              this.curentGroupArrayIndex,
              this.currQueIndex,
            );
            this.getSubjectDetails(this.subjectId);
            this.practiceNextNewQuestion();
          } else {
            console.log(">>>> 1 >>>> Ending session in practice resume");
            this.endPracticeSession();
          }
        });
      } else if (
        this.$route.params.questionList === undefined &&
        this.$route.params.UserTestSessionId === undefined
      ) {
        const isSubjectiveSession = Number(localStorage.getItem("isSubjective"));
        if (isSubjectiveSession === 0) {
          this.practiceSessionData = JSON.parse(localStorage.getItem("PracticeSessionRefreshData"));
          this.userTestSessionId = this.practiceSessionData.practiceId;
          this.queGrpList = this.practiceSessionData.practiceQuestionsList;
          this.curentGroupArrayIndex = this.practiceSessionData.currentQuestionGroupIndex;
          this.currQueIndex = this.practiceSessionData.currentQuestionIndex;
          this.practiceSessionScore = this.practiceSessionData.practiceScoreList;
          this.kscList = this.practiceSessionData.kscList;
          this.subjectList = this.practiceSessionData.subjectList;
          this.averageTime = this.subjectList.userSubject.AvgTime;
          this.attemptMedian = this.subjectList.globalStats.AttemptedMedian;
          this.subjectAccuracy = this.subjectList.userSubject.Accuracy.replace("%", "");
          this.subjectAccuracy = Number(this.subjectAccuracy);
          this.chapterList = this.practiceSessionData.chapterList;
          this.subjectId = this.practiceSessionData.subjectId;
          this.courseid = this.practiceSessionData.courseId;
          this.subjectName = this.practiceSessionData.subjectName;
          this.className = this.practiceSessionData.className;
          this.promptsQuestion = this.practiceSessionData.promptQuestionData;
          this.promptsVariables = this.practiceSessionData.practicePromptsData;
          // eslint-disable-next-line no-unused-expressions
          this.practiceSessionData.shownPromptsList.includes(",") &&
          this.practiceSessionData.shownPromptsList !== ""
            ? this.shownPrompts.push(this.resumeSessionData.shownPromptsList.split(","))
            : this.shownPrompts.push(this.resumeSessionData.shownPromptsList);
          const initialQuestion = null;
          const isResumeSession = 1;
          this.newQuestionSelectionFunction(
            this.queGrpList,
            initialQuestion,
            isResumeSession,
            this.practiceSessionScore,
            this.curentGroupArrayIndex,
            this.currQueIndex,
          );
          this.getSubjectDetails(this.subjectId);
          this.practiceNextNewQuestion();
        } else if (isSubjectiveSession === 1) {
          this.practiceSessionData = JSON.parse(localStorage.getItem("PracticeSessionRefreshData"));
          this.subjectiveQuestionIndex = this.practiceSessionData.quesIndex;
          this.questionList = this.practiceSessionData.questionList;
          this.userTestSessionId = Number(this.practiceSessionData.practiceId);
          this.className = this.practiceSessionData.className;
          this.subjectName = this.practiceSessionData.subjectName;
          this.practiceLevel = this.practiceSessionData.PracticeLevel;
          this.courseId = Number(this.practiceSessionData.courseId);
          this.subjectId = Number(this.practiceSessionData.subjectId);
          this.getSubjectDetails(this.subjectId);
          this.queGrpList = this.questionList;
          this.nextSubjectiveQuestion(this.queGrpList);
          this.practiceNextNewQuestion();
        }
      }
    } else if (this.isPastYear === 1) {
      if (
        this.$route.params.questionList !== undefined &&
        this.$route.params.UserTestSessionId !== undefined
      ) {
        localStorage.setItem("isSubjective", Number(this.$route.params.isSubjective));
        this.isSubjective = Number(this.$route.params.isSubjective);
        this.questionList = JSON.parse(this.$route.params.questionList);
        if (this.isDppSession === 0) {
          this.kscList = JSON.parse(this.$route.params.kscList);
          this.chapterList = JSON.parse(this.$route.params.chapterList);
          this.subjectList = JSON.parse(this.$route.params.subjectList);
        }
        this.userTestSessionId = Number(this.$route.params.UserTestSessionId);
        this.className = this.$route.params.className;
        this.subjectName = this.$route.params.subjectName;
        this.practiceLevel = this.$route.params.PracticeLevel;
        this.courseId = Number(this.$route.params.coureId);
        this.subjectId = Number(this.$route.params.subjectId);
        this.getSubjectDetails(this.subjectId);
        this.queGrpList = this.questionList;
        this.pastYearQuestionIndex = 0;
        this.nextPastYearQuestion(this.queGrpList);
        this.practiceNextNewQuestion();
      } else {
        this.practiceSessionData = JSON.parse(localStorage.getItem("PracticeSessionRefreshData"));
        this.userTestSessionId = this.practiceSessionData.practiceId;
        this.queGrpList = this.practiceSessionData.practiceQuestionsList;
        this.curentGroupArrayIndex = this.practiceSessionData.currentQuestionGroupIndex;
        this.currQueIndex = this.practiceSessionData.currentQuestionIndex;
        this.practiceSessionScore = this.practiceSessionData.practiceScoreList;
        this.kscList = this.practiceSessionData.kscList;
        this.subjectList = this.practiceSessionData.subjectList;
        this.averageTime = this.subjectList.userSubject.AvgTime;
        this.attemptMedian = this.subjectList.globalStats.AttemptedMedian;
        this.subjectAccuracy = this.subjectList.userSubject.Accuracy.replace("%", "");
        this.subjectAccuracy = Number(this.subjectAccuracy);
        this.chapterList = this.practiceSessionData.chapterList;
        this.subjectId = this.practiceSessionData.subjectId;
        this.courseid = this.practiceSessionData.courseId;
        this.subjectName = this.practiceSessionData.subjectName;
        this.className = this.practiceSessionData.className;
        this.promptsQuestion = this.practiceSessionData.promptQuestionData;
        this.promptsVariables = this.practiceSessionData.practicePromptsData;
        this.pastYearQuestionIndex = Number(localStorage.getItem("pastYearQuestionIndex"));
        this.getSubjectDetails(this.subjectId);
        this.nextPastYearQuestion(this.queGrpList);
        this.practiceNextNewQuestion();
      }
    }
    this.$store.dispatch("showLoader", true);
  },
  mounted() {
    window.onresize = () => {
      this.windowWidth = window.innerWidth;
    };
    window.addEventListener("beforeunload", (event) => {
      this.showRefreshModal();
      event.preventDefault();
    });
    let prevKey = "";
    window.addEventListener("keydown", (e) => {
      if (e.key === "F5") {
        this.showRefreshModal();
      } else if (e.key.toUpperCase() === "W" && prevKey === "CONTROL") {
        window.addEventListener("beforeunload", (event) => {
          event.preventDefault();
          this.endPracticeSession();
        });
      } else if (e.key.toUpperCase() === "R" && prevKey === "CONTROL") {
        this.showRefreshModal();
      } else if (e.key.toUpperCase() === "F4" && (prevKey === "ALT" || prevKey === "CONTROL")) {
        window.addEventListener("beforeunload", (event) => {
          event.preventDefault();
          this.endPracticeSession();
        });
      }
      prevKey = e.key.toUpperCase();
    });
  },
  computed: {
    markTextHandler() {
      if (this.isMarkForClarification) {
        return "Marked";
      }
      return "Mark";
    },
  },
  methods: {
    getAzureUrl(imageUrl) {
      if (imageUrl.startsWith("~")) {
        imageUrl = imageUrl.replace("~", process.env.VUE_APP_STORAGE_URL);
      }
      return imageUrl;
    },
    getSubjectDetails(subjectId) {
      MobileAPI.getSubjectDetails(
        {
          subjectId,
        },
        (subject) => {
          this.subjectIconURL = subject.data[0].DashboardIconUrl;
          this.subBgColor = subject.data[0].BgColor + 20;
          if (this.subBgColor === "") {
            this.subBgColor = "#f9a82520";
          }
        },
      );
      return 0;
    },
    practiceNextNewQuestion() {
      const dataObj = {
        questionId: this.selectedQuestionData.QuestionId,
        userId: Number(this.userDetails.UserId),
        practiceId: this.userTestSessionId,
      };
      MobileAPI.getPracticeNextQuestionNew(dataObj, (ques) => {
        this.$store.dispatch("showLoader", false);
        let beginDate = moment();
        beginDate = beginDate.format("YYYY-MM-DD HH:MM");
        this.startDate = beginDate;
        if (Object.values(ques.data).length !== 0) {
          this.testTypeId = Number(ques.data.questionData.questionTypeId);
          this.getQuestionData(ques);
        } else {
          console.log(">>>> 2 >>>> Ending session in practice resume");
          this.endPracticeSession();
        }
      });
    },
    compareDateTime() {
      const currentTime = moment(new Date()).format("HH:mm");
      if (currentTime >= this.lpsEndTime) {
        this.lpsId = null;
        localStorage.removeItem("LpsId");
        localStorage.removeItem("LpsEndTime");
      }
    },
    getQuestionData(ques) {
      if (this.testTypeId === 1) {
        this.showSubjective = false;
        this.showMultiOption = false;
        this.showNumeric = false;
        this.showOptions = true;
      } else if (this.testTypeId === 2) {
        this.showOptions = false;
        this.showMultiOption = false;
        this.showNumeric = false;
        this.showSubjective = true;
      } else if (this.testTypeId === 3) {
        this.showSubjective = false;
        this.showOptions = false;
        this.showNumeric = false;
        this.showMultiOption = true;
      } else if (this.testTypeId === 4) {
        this.showMultiOption = false;
        this.showSubjective = false;
        this.showOptions = false;
        this.showNumeric = true;
      } else if (this.testTypeId === 5) {
        this.showSubjective = false;
        this.showMultiOption = false;
        this.showNumeric = false;
        this.showOptions = true;
      }
      this.questionTimer();
      this.questionImage = ques.data.questionData.questionDiagramURL;
      this.questionAnswer = ques.data.questionData.answerOption;
      this.questionId = ques.data.questionData.questionId;
      this.questionScore = ques.data.questionData.questionScore;
      this.questionData = ques.data.questionData;
      this.questionLatexStatus = ques.data.questionData.latexStatus;
      this.questionLatex = ques.data.questionData.q_Latex;
      this.questionLatexDiagram = JSON.parse(ques.data.questionData.diagram);
      if (this.questionLatexDiagram !== null) {
        this.questionPosition = this.questionLatexDiagram.diagram_0.position;
        this.questionDiagram = this.questionLatexDiagram.diagram_0.url;
      }
      this.questionOptionA = ques.data.questionData.answerOptionA;
      this.questionOptionB = ques.data.questionData.answerOptionB;
      this.questionOptionC = ques.data.questionData.answerOptionC;
      this.questionOptionD = ques.data.questionData.answerOptionD;
      this.kscData = ques.data.kscDataList;
      this.userTestSessionQuestionId = ques.data.userTestSessionQuestionId;
      this.kscDiagram = this.kscData[0].kscDiagramURL;
    },
    showRefreshModal() {
      const isSubjectiveSession = Number(localStorage.getItem("isSubjective"));
      if (isSubjectiveSession === 0) {
        for (let i = 0; i < this.questionListArray.length; i += 1) {
          this.practiceSessionScore.push({
            netGroupScore: this.questionListArray[i].netGroupScore,
            groupCorrectSCore: this.questionListArray[i].groupCorrectScore,
            startIndexPosition: this.questionListArray[i].startIndexPosition,
            previousQnIndex: this.selectedQuestionData.QuestionId,
          });
        }
        const data = {
          practiceId: this.userTestSessionId,
          userId: Number(this.userDetails.UserId),
          practiceQuestionsList: this.queGrpList,
          currentQuestionGroupIndex: this.curentGroupArrayIndex,
          currentQuestionIndex: this.currentQnIndex,
          practiceScoreList: this.practiceSessionScore,
          kscList: this.kscList,
          subjectList: this.subjectList,
          chapterList: this.chapterList,
          subjectName: this.subjectName,
          className: this.className,
          courseId: this.courseId,
          subjectId: this.subjectId,
          practiceStatus: "Pause",
          practicePromptsData: this.promptsVariables,
          promptQuestionData: this.promptsQuestion,
          shownPromptsList: this.shownPrompts.length > 0 ? this.shownPrompts.join(",") : "",
        };
        localStorage.setItem("PracticeSessionRefreshData", JSON.stringify(data));
      } else if (isSubjectiveSession === 1) {
        const subjectiveSessionData = {
          quesIndex: this.subjectiveQuestionIndex,
          questionList: this.queGrpList,
          subjectName: this.subjectName,
          className: this.className,
          courseId: this.courseId,
          subjectId: this.subjectId,
          practiceId: this.userTestSessionId,
          userId: Number(this.userDetails.UserId),
        };
        localStorage.setItem("PracticeSessionRefreshData", JSON.stringify(subjectiveSessionData));
      }
    },
    questionTimer() {
      this.timer = setTimeout(() => {
        this.timeTaken += 1;
        this.questionTimer();
      }, 1000);
    },
    sortArray(questiongroupList, isPositive) {
      const sortedArray = [];
      if (questiongroupList != null && questiongroupList.length > 0) {
        const groupStartIndex = questiongroupList.findIndex((a) => a.GroupStart === 1);
        if (groupStartIndex >= 0) {
          const splitArray1 = questiongroupList.slice(0, groupStartIndex).reverse();
          const splitArray2 = questiongroupList.slice(groupStartIndex + 1);
          sortedArray.push(questiongroupList[groupStartIndex]);
          if (isPositive) {
            if (splitArray2 != null && splitArray2.length > 0) {
              splitArray2.forEach((element) => {
                sortedArray.push(element);
              });
            }

            if (splitArray1 != null && splitArray1.length > 0) {
              splitArray1.forEach((element) => {
                sortedArray.push(element);
              });
            }
          } else {
            if (splitArray1 != null && splitArray1.length > 0) {
              splitArray1.forEach((element) => {
                sortedArray.push(element);
              });
            }
            if (splitArray2 != null && splitArray2.length > 0) {
              splitArray2.forEach((element) => {
                sortedArray.push(element);
              });
            }
          }
        }
      }
      this.newSortedQuestionArray = sortedArray;
    },
    nextSubjectiveQuestion(questionList) {
      if (questionList != null) {
        this.questionListArray = Object.values(questionList);
        this.totalQuestionList = this.questionListArray.concat.apply([], this.questionListArray);
        this.totalQuestionCount = this.totalQuestionList.length;
        if (this.subjectiveQuestionIndex > this.totalQuestionCount - 1) {
          this.selectedQuestionData = [];
        } else {
          this.selectedQuestionData = this.totalQuestionList[this.subjectiveQuestionIndex];
        }
      }
    },
    nextPastYearQuestion(questionList) {
      if (questionList != null) {
        this.questionListArray = Object.values(questionList);
        this.totalQuestionList = this.questionListArray;
        this.totalQuestionCount = this.totalQuestionList.length;
        if (this.pastYearQuestionIndex > this.totalQuestionCount - 1) {
          this.selectedQuestionData = [];
          this.showQueEndModal = true;
        } else {
          this.selectedQuestionData = this.totalQuestionList[this.pastYearQuestionIndex];
        }
      }
    },
    newQuestionSelectionFunction(
      questionList,
      initialQuestion,
      isResumeSession,
      practiceSessionScore,
      quesGroupIndex,
      quesIndex,
    ) {
      this.newQuestionCount = 0;
      this.totalQuestionCount = 0;
      this.easyQuestionCount = 0;
      this.hardQuestionCount = 0;
      this.attemptCount = 0;
      this.correctQuestionCount = 0;
      this.viewedQuestionCount = 0;
      this.attemptedQuestionCount = 0;
      if (questionList != null) {
        this.questionListArray = Object.values(questionList);
        this.totalQuestionList = this.questionListArray.concat.apply([], this.questionListArray);
        if (this.totalQuestionList.filter((a) => a.Viewed === 0).length === 1) {
          this.selectedQuestionData = this.totalQuestionList.filter((a) => a.Viewed === 0);
          this.selectedQuestionData = this.selectedQuestionData[0];
        }
        if (this.totalQuestionList.filter((a) => a.Viewed === 0).length === 0) {
          this.allAttempted = true;
        }
        this.totalQuestionList.forEach((obj) => {
          if (obj.Viewed === 1) {
            this.viewedQuestionCount += 1;
          }
          if (obj.Attempted === 1) {
            this.attemptedQuestionCount += 1;
          }
        });
        if (this.totalQuestionList.filter((a) => a.Viewed === 0).length > 1) {
          this.newQuestionList = this.totalQuestionList.filter((a) => a.QuestionStatus === "New");
          this.easyQuestionList = this.totalQuestionList.filter((a) => a.RelativeDifficulty <= 0);
          this.hardQuestionList = this.totalQuestionList.filter((a) => a.RelativeDifficulty > 0);
          this.newQuestionList.forEach((obj) => {
            if (obj.Viewed === 1) {
              this.newQuestionCount += 1;
              if (this.newQuestionCount === this.newQuestionList.length) {
                this.showNoNew += 1;
                if (this.showNoNew === 1) {
                  this.noMoreNewQuestion = true;
                }
              }
            }
          });
          this.totalQuestionList.forEach((obj) => {
            if (obj.Viewed === 1) {
              this.totalQuestionCount += 1;
              if (this.totalQuestionCount === this.totalQuestionList.length) {
                this.allAttempted = true;
              }
            }
          });
          this.easyQuestionList.forEach((obj) => {
            if (obj.Viewed === 1) {
              this.easyQuestionCount += 1;
              if (this.easyQuestionCount === this.easyQuestionList.length) {
                this.showNoEasy += 1;
                if (this.showNoEasy === 1) {
                  this.noEasyQuestion = true;
                }
              }
            }
          });
          this.hardQuestionList.forEach((obj) => {
            if (obj.Viewed === 1) {
              this.hardQuestionCount += 1;
              if (this.hardQuestionCount === this.hardQuestionList.length) {
                this.showNoHard += 1;
                if (this.showNoHard === 1) {
                  this.noHardQuestion = true;
                }
              }
            }
          });

          // first question
          let startIndex = null;
          if (initialQuestion === 1) {
            for (let i = 0; i < this.questionListArray.length; i += 1) {
              this.questionListArray[i].netGroupScore = 0;
              this.questionListArray[i].groupCorrectScore = 0;
              this.questionListArray[i].startIndexPosition = this.questionListArray[i].findIndex(
                (a) => a.GroupStart === 1,
              );
              this.questionListArray[i].previousQnIndex = 0;
            }
            for (let i = 0; i < this.questionListArray.length; i += 1) {
              for (let j = 0; j < this.questionListArray[i].length; j += 1) {
                this.questionListArray[i][j].QuestionDiagramURL = this.getAzureUrl(
                  this.questionListArray[i][j].QuestionDiagramURL,
                );
              }
            }
            startIndex = this.questionListArray[this.curentGroupArrayIndex].startIndexPosition;
            this.selectedQuestionData =
              this.questionListArray[this.curentGroupArrayIndex][startIndex];
            this.selectedQuestionId = this.selectedQuestionData.QuestionId;
            this.questionListArray[this.curentGroupArrayIndex].previousQnIndex = startIndex;
            this.currentQnGroup = this.selectedQuestionData.QuestionGroup;
            this.currentQnIndex = startIndex;
            this.pauseSessionQuestionList = this.questionListArray;
          } else if (initialQuestion === 0) {
            // this.newSortedQuestionArray[this.currentQnIndex] = this.selectedQuestionData;
            // this.questionListArray[this.curentGroupArrayIndex] = this.newSortedQuestionArray;
            // console.log("QuesitonList", this.questionListArray);
            if (
              this.selectedQuestionData.Attempted === 1 &&
              this.selectedQuestionData.Corrected === 1
            ) {
              this.questionListArray[this.curentGroupArrayIndex].groupCorrectScore += 1;
              this.questionListArray[this.curentGroupArrayIndex].netGroupScore += 1;
            } else if (
              this.selectedQuestionData.Attempted === 1 &&
              this.selectedQuestionData.Corrected === 0
            ) {
              this.questionListArray[this.curentGroupArrayIndex].netGroupScore -= 1;
            } else if (
              this.selectedQuestionData.Attempted === 0 &&
              this.selectedQuestionData.Corrected === 0
            ) {
              this.questionListArray[this.curentGroupArrayIndex].netGroupScore -= 1;
            }
            if (
              this.questionListArray[this.curentGroupArrayIndex].groupCorrectScore === 5 ||
              this.questionListArray[this.curentGroupArrayIndex].netGroupScore === -5
            ) {
              this.curentGroupArrayIndex += 1;
              if (this.curentGroupArrayIndex > this.questionListArray.length - 1) {
                this.curentGroupArrayIndex = 0;
                for (let i = 0; i < this.questionListArray.length; i += 1) {
                  this.questionListArray[i].netGroupScore = 0;
                  this.questionListArray[i].groupCorrectScore = 0;
                  this.questionListArray[i].startIndexPosition = this.questionListArray[
                    i
                  ].findIndex((a) => a.GroupStart === 1);
                }
              }
              this.currentQnIndex = 0;
              this.sortArray(
                this.questionListArray[this.curentGroupArrayIndex],
                Boolean(this.selectedQuestionData.Corrected),
              );
            }
            // console.log("Group Index", this.curentGroupArrayIndex);
            // console.log(this.newSortedQuestionArray);
            // this.selectedQuestionData = this.newSortedQuestionArray[this.currentQnIndex];
            let isHavingQuestion = this.checkForQuestionDifficulty(
              this.questionListArray[this.curentGroupArrayIndex],
              Boolean(this.selectedQuestionData.Corrected),
            );
            while (isHavingQuestion !== true) {
              this.curentGroupArrayIndex += 1;
              if (this.curentGroupArrayIndex > this.questionListArray.length - 1) {
                this.curentGroupArrayIndex = 0;
                for (let i = 0; i < this.questionListArray.length; i += 1) {
                  this.questionListArray[i].netGroupScore = 0;
                  this.questionListArray[i].groupCorrectScore = 0;
                }
              }
              isHavingQuestion = this.checkForQuestionDifficulty(
                this.questionListArray[this.curentGroupArrayIndex],
                Boolean(this.selectedQuestionData.Corrected),
              );
              if (this.checkQuestion === true) {
                // console.log("i Came here ");
                break;
              }
            }
            this.sortArray(
              this.questionListArray[this.curentGroupArrayIndex],
              Boolean(this.selectedQuestionData.Corrected),
            );
            while (this.selectedQuestionData.Viewed === 1) {
              this.currentQnIndex += 1;
              if (this.currentQnIndex > this.newSortedQuestionArray.length - 1) {
                this.curentGroupArrayIndex += 1;
                if (this.curentGroupArrayIndex > this.questionListArray.length - 1) {
                  this.curentGroupArrayIndex = 0;
                  for (let i = 0; i < this.questionListArray.length; i += 1) {
                    this.questionListArray[i].netGroupScore = 0;
                    this.questionListArray[i].groupCorrectScore = 0;
                  }
                }
                this.sortArray(
                  this.questionListArray[this.curentGroupArrayIndex],
                  Boolean(this.selectedQuestionData.Corrected),
                );
                this.currentQnIndex = 0;
              }
              // console.log("question index", this.currentQnIndex);
              this.selectedQuestionData = this.newSortedQuestionArray[this.currentQnIndex];
              if (this.selectedQuestionData.QuestionId === undefined) {
                // console.log("i came from here");
                this.allAttempted = true;
              }
              // console.log("question", this.selectedQuestionData);
            }
            this.pauseSessionQuestionList = this.questionListArray;
          } else if (isResumeSession === 1) {
            this.questionListArray = Object.values(questionList);
            this.curentGroupArrayIndex = quesGroupIndex;
            this.currentQnIndex = quesIndex;
            for (let i = 0; i < this.questionListArray.length; i += 1) {
              this.questionListArray[i].netGroupScore = practiceSessionScore[i].netGroupScore;
              this.questionListArray[i].groupCorrectScore =
                practiceSessionScore[i].groupCorrectSCore;
              this.questionListArray[i].startIndexPosition =
                practiceSessionScore[i].startIndexPosition;
              this.questionListArray[i].previousQnIndex = practiceSessionScore[i].previousQnIndex;
            }
            this.practiceSessionScore = [];
            this.selectedQuestionData = this.questionListArray[this.curentGroupArrayIndex].find(
              (a) =>
                a.QuestionId === this.questionListArray[this.curentGroupArrayIndex].previousQnIndex,
            );
            this.questionSelection();
            this.pauseSessionQuestionList = this.questionListArray;
          }
        }
      }
    },
    checkForQuestionDifficulty(questionList, isCorrect) {
      // eslint-disable-next-line no-unused-vars
      let questionArrayList = [];
      let totalQuestionArrayList = [];
      if (!isCorrect) {
        questionArrayList = questionList.filter((a) => a.RelativeDifficulty <= 0 && a.Viewed === 0);
      } else {
        questionArrayList = questionList.filter((a) => a.RelativeDifficulty > 0 && a.Viewed === 0);
      }
      if (!isCorrect) {
        totalQuestionArrayList = this.totalQuestionList.filter(
          (a) => a.RelativeDifficulty <= 0 && a.Viewed === 0,
        );
      } else {
        totalQuestionArrayList = this.totalQuestionList.filter(
          (a) => a.RelativeDifficulty > 0 && a.Viewed === 0,
        );
      }
      if (totalQuestionArrayList === null || totalQuestionArrayList.length === 0) {
        this.checkQuestion = true;
      }
      if (questionArrayList === null || questionArrayList.length === 0) {
        return false;
      }
      return true;
    },
    questionSelection() {
      if (this.selectedQuestionData.Attempted === 1 && this.selectedQuestionData.Corrected === 1) {
        this.questionListArray[this.curentGroupArrayIndex].groupCorrectScore += 1;
        this.questionListArray[this.curentGroupArrayIndex].netGroupScore += 1;
      } else if (
        this.selectedQuestionData.Attempted === 1 &&
        this.selectedQuestionData.Corrected === 0
      ) {
        this.questionListArray[this.curentGroupArrayIndex].netGroupScore -= 1;
      } else if (
        this.selectedQuestionData.Attempted === 0 &&
        this.selectedQuestionData.Corrected === 0
      ) {
        this.questionListArray[this.curentGroupArrayIndex].netGroupScore -= 1;
      }
      if (
        this.questionListArray[this.curentGroupArrayIndex].groupCorrectScore === 5 ||
        this.questionListArray[this.curentGroupArrayIndex].netGroupScore === -5
      ) {
        this.curentGroupArrayIndex += 1;
        this.sortArray(
          this.questionListArray[this.curentGroupArrayIndex],
          Boolean(this.selectedQuestionData.Corrected),
        );
        this.currentQnIndex = 0;
      }
      if (this.curentGroupArrayIndex > this.questionListArray.length - 1) {
        this.curentGroupArrayIndex = 0;
        this.sortArray(
          this.questionListArray[this.curentGroupArrayIndex],
          Boolean(this.selectedQuestionData.Corrected),
        );
        this.currentQnIndex = 0;
      }
      // this.selectedQuestionData = this.newSortedQuestionArray[this.currentQnIndex];
      this.sortArray(
        this.questionListArray[this.curentGroupArrayIndex],
        Boolean(this.selectedQuestionData.Corrected),
      );
      while (this.selectedQuestionData.Viewed === 1) {
        this.currentQnIndex += 1;
        if (this.currentQnIndex > this.newSortedQuestionArray - 1) {
          this.curentGroupArrayIndex += 1;
          this.sortArray(
            this.questionListArray[this.curentGroupArrayIndex],
            Boolean(this.selectedQuestionData.Corrected),
          );
          this.currentQnIndex = 0;
        }
        this.selectedQuestionData = this.newSortedQuestionArray[this.currentQnIndex];
      }
    },
    // return this.selectedQuestionData;
    simulateUserResponse(isCorrect, timeTaken, answerOption, tryAgain, attemptedOn) {
      if (tryAgain === 0) {
        if (isCorrect === 0) {
          this.selectedQuestionData.Viewed = 1;
          this.selectedQuestionData.Attempted = 1;
          this.selectedQuestionData.Corrected = 0;
          this.selectedQuestionData.TotalTime = timeTaken;
          this.selectedQuestionData.answerOption = answerOption;
          this.selectedQuestionData.attemptedOn = attemptedOn;
          this.selectedQuestionData.isCorrectlyAnsweredSecondTime = 0;
        } else if (isCorrect === 1) {
          this.selectedQuestionData.Viewed = 1;
          this.selectedQuestionData.Attempted = 1;
          this.selectedQuestionData.Corrected = 1;
          this.selectedQuestionData.TotalTime = timeTaken;
          this.selectedQuestionData.answerOption = answerOption;
          this.selectedQuestionData.attemptedOn = attemptedOn;
          this.selectedQuestionData.isCorrectlyAnsweredSecondTime = 0;
        }
      } else if (tryAgain === 1) {
        if (isCorrect === 0) {
          this.selectedQuestionData.Viewed = 1;
          this.selectedQuestionData.Attempted = 1;
          this.selectedQuestionData.Corrected = 0;
          // eslint-disable-next-line operator-assignment
          this.selectedQuestionData.TotalTime = this.selectedQuestionData.TotalTime + timeTaken;
          this.selectedQuestionData.answerOptionSecondTime = answerOption;
          this.selectedQuestionData.attemptedOnSecondTime = attemptedOn;
          this.selectedQuestionData.isCorrectlyAnsweredSecondTime = 0;
        } else if (isCorrect === 1) {
          this.selectedQuestionData.Viewed = 1;
          this.selectedQuestionData.Attempted = 1;
          this.selectedQuestionData.Corrected = 0;
          // eslint-disable-next-line operator-assignment
          this.selectedQuestionData.TotalTime = this.selectedQuestionData.TotalTime + timeTaken;
          this.selectedQuestionData.answerOptionSecondTime = answerOption;
          this.selectedQuestionData.attemptedOnSecondTime = attemptedOn;
          this.selectedQuestionData.isCorrectlyAnsweredSecondTime = 1;
        }
      }
      if (this.tryAgain === 0) {
        if (this.promptsQuestion != null && this.promptsQuestion.length > 0) {
          const isQnExists = this.promptsQuestion.findIndex(
            (a) => a.QuestionId === this.selectedQuestionData.QuestionId,
          );
          if (isQnExists >= 0) {
            this.promptsQuestion.splice(isQnExists, 1, this.selectedQuestionData);
          } else {
            this.promptsQuestion.push(this.selectedQuestionData);
          }
        } else {
          this.promptsQuestion.push(this.selectedQuestionData);
        }
      }
      // console.log(this.promptsQuestion);

      return this.selectedQuestionData;
    },
    markQuestionForClarification(isMarkForClarification) {
      if (isMarkForClarification === true) {
        this.selectedQuestionData.Viewed = 1;
        this.selectedQuestionData.markForClarification = 1;
      } else {
        this.selectedQuestionData.Viewed = 1;
        this.selectedQuestionData.markForClarification = 0;
      }
      return this.selectedQuestionData;
    },
    markQuestionForRevision(isMarkForRevision) {
      if (isMarkForRevision === true) {
        this.selectedQuestionData.Viewed = 1;
        this.selectedQuestionData.markForRevision = 1;
      } else if (isMarkForRevision === false) {
        this.selectedQuestionData.Viewed = 1;
        this.selectedQuestionData.markForRevision = 0;
      }
      return this.selectedQuestionData;
    },
    questionSkip(isSkipped, tryAgain, timeTaken) {
      if (tryAgain === 0) {
        if (isSkipped === true) {
          this.selectedQuestionData.Viewed = 1;
          this.selectedQuestionData.Attempted = 0;
          this.selectedQuestionData.Corrected = 0;
          this.selectedQuestionData.TotalTime = timeTaken;
        }
      } else if (tryAgain === 1) {
        this.selectedQuestionData.Viewed = 1;
        this.selectedQuestionData.Attempted = 1;
        this.selectedQuestionData.Corrected = 0;
      }
      if (tryAgain === 0) {
        if (this.promptsQuestion != null && this.promptsQuestion.length > 0) {
          const isQnExists = this.promptsQuestion.findIndex(
            (a) => a.QuestionId === this.selectedQuestionData.QuestionId,
          );
          if (isQnExists >= 0) {
            this.promptsQuestion.splice(isQnExists, 1, this.selectedQuestionData);
          } else {
            this.promptsQuestion.push(this.selectedQuestionData);
          }
        } else {
          this.promptsQuestion.push(this.selectedQuestionData);
        }
      }
      // console.log(this.promptsQuestion);
      return this.selectedQuestionData;
    },
    validateNumber: (event) => {
      const keyCode = event.keyCode;
      if (keyCode > 31 && (keyCode < 48 || keyCode > 57)) {
        event.preventDefault();
      }
    },
    showAllAttemptedModal() {
      this.allAttempted = true;
    },
    closeScore() {
      this.showScore = false;
    },
    checkAnswer(event) {
      this.selectedOption = event.target.value;
    },
    checkMultiOption(event) {
      if (this.selectedMultiOption.includes(event.target.value) === true) {
        this.selectedMultiOption = this.selectedMultiOption.replace(event.target.value, "");
      } else if (this.selectedMultiOption.includes(event.target.value) === false) {
        this.selectedMultiOption = this.selectedMultiOption.concat(event.target.value);
      }
    },
    showPauseModal() {
      this.pauseModal = true;
    },
    submitErrorReport() {
      const data = {
        userTestSessionQuestionId: this.userTestSessionQuestionId,
        tagId: parseInt(this.reportErrorValue),
      };
      Doubtapi.UserSessionErrorReport(data, (response) => {
        if (response.status === 200) {
          this.showErrorReport = false;
          this.errorReportModal = true;
        }
      });
      this.reportErrorValue = "";
      this.reportModal = false;
    },
    getUserQuestionPrompts() {
      const resultArray = [];
      // eslint-disable-next-line no-unused-vars
      let promptNumber = null;
      // eslint-disable-next-line no-unused-vars
      let priorityNumber = "";
      // Concept mastery rule
      // 1 - get the previous question group first
      const prevQuestion = this.promptsQuestion[this.promptsQuestion.length - 1];
      if (this.questionListArray[this.curentGroupArrayIndex].length !== 0) {
        let toughcount = 0;
        toughcount = this.questionListArray[this.curentGroupArrayIndex].filter(
          (obj) =>
            (obj.DifficultyGroup === "Hard" || obj.DifficultyGroup === "Tough") &&
            obj.Corrected === 1,
        );
        if (toughcount === 5) {
          promptNumber = 0;
          priorityNumber = "P2";
          resultArray.push({
            number: promptNumber,
            priority: priorityNumber,
          });
          this.start();
        }
      }
      if (prevQuestion.DifficultyGroup === "Tough" && prevQuestion.Corrected === 1) {
        const isToughnutCracked = true;
        if (isToughnutCracked) {
          promptNumber = 1;
          priorityNumber = "P2";
          resultArray.push({
            number: promptNumber,
            priority: priorityNumber,
          });
          this.start();
        }
      }
      if (
        this.promptsVariables.last10Correct === 10 &&
        this.promptsVariables.last20Correct !== 20
      ) {
        const isLast10CorrectPrompt = true;
        if (isLast10CorrectPrompt) {
          promptNumber = 2;
          priorityNumber = "P1";
          resultArray.push({
            number: promptNumber,
            priority: priorityNumber,
          });
          this.start();
          this.promptsVariables.last10Correct = 0;
        }
      }
      if (this.promptsVariables.last20Correct === 20) {
        const isLast20CorrectPrompt = true;
        if (isLast20CorrectPrompt) {
          promptNumber = 3;
          priorityNumber = "P2";
          resultArray.push({
            number: promptNumber,
            priority: priorityNumber,
          });
          this.start();
        }
      }
      if (this.promptsVariables.last10Wrong === 10) {
        const isLast10Wrong = true;
        if (isLast10Wrong) {
          priorityNumber = "P1";
          promptNumber = 4;
          resultArray.push({
            number: promptNumber,
            priority: priorityNumber,
          });
          this.promptsVariables.last10Wrong = 0;
        }
      }
      if (this.promptsVariables.last20Wrong === 20) {
        const isLast20Wrong = true;
        if (isLast20Wrong) {
          priorityNumber = "P1";
          promptNumber = 5;
          resultArray.push({
            number: promptNumber,
            priority: priorityNumber,
          });
        }
      }
      if (
        prevQuestion.DifficultyGroup === "Easy" &&
        prevQuestion.Corrected === 0 &&
        prevQuestion.Attempted === 1
      ) {
        this.isEasyMiss += 1;
        if (this.isEasyMiss === 1) {
          priorityNumber = "P2";
          promptNumber = 6;
          resultArray.push({
            number: promptNumber,
            priority: priorityNumber,
          });
        }
        if (this.isEasyMiss === 5) {
          this.isEasyMiss = 0;
        }
      }
      if (this.promptsVariables.last5Skip === 5) {
        const is5SKip = true;
        if (is5SKip) {
          promptNumber = 15;
          priorityNumber = "P2";
          resultArray.push({
            number: promptNumber,
            priority: priorityNumber,
          });
          this.promptsVariables.last5Skip = 0;
        }
      }

      if (
        this.promptsVariables.totalAttempt >= 20 &&
        (this.promptsVariables.totalAttempt - 2) % 10 === 0
      ) {
        const correctPercent =
          this.promptsVariables.totalCorrect / this.promptsVariables.totalAttempt;
        // console.log(correctPercent);
        if (this.promptsVariables.totalAttempt >= 20 && correctPercent > 0.5) {
          priorityNumber = "P1";
          promptNumber = 12;
          resultArray.push({
            number: promptNumber,
            priority: priorityNumber,
          });
          this.start();
        }
      }

      if (
        this.promptsVariables.totalAttempt >= 30 &&
        (this.promptsVariables.totalAttempt - 2) % 10 === 0
      ) {
        const coinsEarn = this.promptsVariables.totalCorrect / this.promptsVariables.totalAttempt;
        if (coinsEarn > 0.8) {
          promptNumber = 13;
          priorityNumber = "P2";
          resultArray.push({
            number: promptNumber,
            priority: priorityNumber,
          });
          this.start();
        }
      }
      if (this.viewedQuestionCount > 10 && this.viewedQuestionCount % 10 === 0) {
        const attemptPercent = this.attemptedQuestionCount / this.viewedQuestionCount;
        if (attemptPercent < 0.7 && this.promptsVariables.last5Attempted === 5) {
          promptNumber = 14;
          priorityNumber = "P1";
          resultArray.push({
            number: promptNumber,
            priority: priorityNumber,
          });
          this.promptsVariables.last5Attempted = 0;
        }
      }

      if (
        this.promptsVariables.totalAttempt >= 10 &&
        (this.promptsVariables.totalAttempt - 1) % 10 === 0
      ) {
        const correctPercent =
          this.promptsVariables.totalCorrect / this.promptsVariables.totalAttempt;
        if (
          this.promptsVariables.totalAttempt > 2 * this.attemptMedian &&
          this.promptsVariables.totalAttempt < 5 * this.attemptMedian &&
          correctPercent > 0.5
        ) {
          promptNumber = 7;
          priorityNumber = "P1";
          resultArray.push({
            number: promptNumber,
            priority: priorityNumber,
          });
          this.start();
        }
        if (this.promptsVariables.totalAttempt > 5 * this.attemptMedian && correctPercent > 0.5) {
          promptNumber = 8;
          priorityNumber = "P2";
          resultArray.push({
            number: promptNumber,
            priority: priorityNumber,
          });
          this.start();
        }
      }
      if (
        this.promptsVariables.totalAttempt >= 10 &&
        (this.promptsVariables.totalAttempt - 1) % 10 === 0
      ) {
        const correctPercent =
          this.promptsVariables.totalCorrect / this.promptsVariables.totalAttempt;
        if (this.promptsVariables.totalTime > 3 * this.promptsVariables.avgTime) {
          promptNumber = 11;
          priorityNumber = "P2";
          resultArray.push({
            number: promptNumber,
            priority: priorityNumber,
          });
          this.promptsVariables.totalTime = 0;
        }
        if (correctPercent > 1.2 * this.subjectAccuracy) {
          promptNumber = 9;
          priorityNumber = "P1";
          resultArray.push({
            number: promptNumber,
            priority: priorityNumber,
          });
          this.start();
        }
        if (correctPercent < 0.8 * this.subjectAccuracy) {
          promptNumber = 10;
          priorityNumber = "P1";
          resultArray.push({
            number: promptNumber,
            priority: priorityNumber,
          });
        }
      }
      if (resultArray.length > 0) {
        if (resultArray.length > 1) {
          let data = [];
          if (resultArray.filter((a) => a.priority === "P2")) {
            data = resultArray.filter((a) => a.priority === "P2");
            this.promptNumber = data[0].number;
            this.promptVisible = true;
            this.showPrompt();
          }
        } else {
          this.promptNumber = resultArray[0].number;
          this.promptVisible = true;
          this.showPrompt();
        }
      }
      if (this.promptVisible) {
        this.shownPrompts.push(this.promptNumber);
      }
      // console.log("prompt number", this.promptsVariables.last5Skip);
    },
    checkPromptsCondition() {
      if (this.selectedQuestionData.Attempted === 1 && this.selectedQuestionData.Corrected === 1) {
        this.promptsVariables.last10Correct += 1;
        this.promptsVariables.last20Correct += 1;
        this.promptsVariables.last10Wrong = 0;
        this.promptsVariables.last20Wrong = 0;
        this.promptsVariables.last5Skip = 0;
        this.promptsVariables.totalAttempt += 1;
        this.promptsVariables.totalCorrect += 1;
        this.promptsVariables.last5Attempted += 1;
        this.promptsVariables.totalTime += this.timeTaken;
        this.promptsVariables.avgTime += this.selectedQuestionData.AvgTimeSecs;
      } else if (
        this.selectedQuestionData.Attempted === 1 &&
        this.selectedQuestionData.Corrected === 0
      ) {
        this.promptsVariables.last10Correct = 0;
        this.promptsVariables.last20Correct = 0;
        this.promptsVariables.last10Wrong += 1;
        this.promptsVariables.last20Wrong += 1;
        this.promptsVariables.last5Skip = 0;
        this.promptsVariables.totalAttempt += 1;
        this.promptsVariables.last5Attempted += 1;
        this.promptsVariables.totalTime += this.timeTaken;
        this.promptsVariables.avgTime += this.selectedQuestionData.AvgTimeSecs;
      } else if (
        this.selectedQuestionData.Attempted === 0 &&
        this.selectedQuestionData.Corrected === 0
      ) {
        this.promptsVariables.last10Correct = 0;
        this.promptsVariables.last20Correct = 0;
        this.promptsVariables.last10Wrong = 0;
        this.promptsVariables.last20Wrong = 0;
        this.promptsVariables.last5Attempted = 0;
        this.promptsVariables.last5Skip += 1;
      }
      this.getUserQuestionPrompts();
    },
    submitAnswer() {
      let beginDate = moment();
      beginDate = beginDate.format("YYYY-MM-DD HH:MM");
      this.isAnsCorrect = false;
      this.promptVisible = false;
      if (this.tryAgain === 0) {
        if (this.selectedOption === this.questionAnswer) {
          this.correctAnswer = true;
          this.showOptions = false;
          this.isAnsCorrect = true;
          this.isCorrect = 1;
          this.viewed = 1;
          this.attempted = 1;
          if (this.isPastYear === 0) {
            this.simulateUserResponse(
              this.isCorrect,
              this.timeTaken,
              this.selectedOption,
              this.tryAgain,
              beginDate,
            );
            this.checkPromptsCondition();
          }
          clearTimeout(this.timer);
          this.timeTaken = 0;
          // new code e
          const Submitdata = {
            userTestSessionQuestionId: this.userTestSessionQuestionId,
            answerOption: String(this.selectedOption),
            isCorrect: 1,
            isFirstTime: true,
            attemptedOn: beginDate,
          };
          Doubtapi.practicesessionsubmitapi(Submitdata, (resp) => {
            this.submit = resp;
          });
          this.answerGiven = this.selectedOption;
          this.selectedOption = "";
        } else {
          this.wrongAnswer = true;
          this.correctAnswer = false;
          this.showOptions = false;
          // new code s
          this.isAnsCorrect = false;
          this.isCorrect = 0;
          this.viewed = 1;
          this.attempted = 1;
          if (this.isPastYear === 0) {
            this.simulateUserResponse(
              this.isCorrect,
              this.timeTaken,
              this.selectedOption,
              this.tryAgain,
              beginDate,
            );
            this.checkPromptsCondition();
          }
          this.timeTaken = 0;
          clearTimeout(this.timer);
          const Submitdata = {
            userTestSessionQuestionId: this.userTestSessionQuestionId,
            answerOption: String(this.selectedOption),
            isCorrect: 0,
            isFirstTime: true,
            attemptedOn: beginDate,
          };

          Doubtapi.practicesessionsubmitapi(Submitdata, (resp) => {
            this.submit = resp;
          });
          this.answerGiven = this.selectedOption;
          this.selectedOption = "";
        }
      }
      if (this.tryAgain === 1) {
        this.currQueIndex = this.tempCurrQueIndex;
        this.netGrpScore = this.tempCorrScore;
        if (this.selectedOption === this.questionAnswer) {
          this.correctAnswer = true;
          this.showOptions = false;
          this.isAnsCorrect = true;
          this.isCorrect = 0;
          this.viewed = 1;
          this.attempted = 1;
          if (this.isPastYear === 0) {
            this.simulateUserResponse(
              this.isCorrect,
              this.timeTaken,
              this.selectedOption,
              this.tryAgain,
              beginDate,
            );
          }
          this.timeTaken = 0;
          clearTimeout(this.timer);
          // new code e
          const Submitdata = {
            userTestSessionQuestionId: this.userTestSessionQuestionId,
            answerOption: String(this.selectedOption),
            isCorrect: 1,
            isFirstTime: false,
            attemptedOn: beginDate,
          };

          Doubtapi.practicesessionsubmitapi(Submitdata, (resp) => {
            this.submit = resp;
          });
          this.answerGiven = this.selectedOption;
          this.selectedOption = "";
        } else {
          this.isAnsCorrect = false;
          this.isCorrect = 0;
          this.viewed = 1;
          this.attempted = 1;
          if (this.isPastYear === 0) {
            this.simulateUserResponse(
              this.isCorrect,
              this.timeTaken,
              this.selectedOption,
              this.tryAgain,
              beginDate,
            );
          }
          this.timeTaken = 0;
          clearTimeout(this.timer);
          const Submitdata = {
            userTestSessionQuestionId: this.userTestSessionQuestionId,
            answerOption: String(this.selectedOption),
            isCorrect: 0,
            isFirstTime: false,
            attemptedOn: beginDate,
          };

          Doubtapi.practicesessionsubmitapi(Submitdata, (resp) => {
            this.submit = resp;
          });
          this.answerGiven = this.selectedOption;
          this.selectedOption = "";
          this.wrongAnswer = true;
          this.showOptions = false;
        }
      }
    },
    sortAlphabets(text) {
      this.selectedMultiOption = text.split("").sort().join("");
    },
    submitMultiAnswer() {
      let beginDate = moment();
      beginDate = beginDate.format("YYYY-MM-DD HH:MM");
      this.isAnsCorrect = false;
      this.promptVisible = false;
      this.showMultiOption = false;
      this.sortAlphabets(this.selectedMultiOption);
      if (this.tryAgain === 0) {
        if (this.selectedMultiOption === this.questionAnswer) {
          this.correctAnswer = true;
          this.isAnsCorrect = true;
          this.showMultiOption = false;
          this.isCorrect = 1;
          this.viewed = 1;
          this.attempted = 1;
          if (this.isPastYear === 0) {
            this.simulateUserResponse(
              this.isCorrect,
              this.timeTaken,
              this.selectedMultiOption,
              this.tryAgain,
              beginDate,
            );
            this.checkPromptsCondition();
          }
          this.timeTaken = 0;
          clearTimeout(this.timer);
          const Submitdata = {
            userTestSessionQuestionId: this.userTestSessionQuestionId,
            answerOption: String(this.selectedMultiOption),
            isCorrect: 1,
            isFirstTime: true,
            attemptedOn: beginDate,
          };
          Doubtapi.practicesessionsubmitapi(Submitdata, (resp) => {
            this.submit = resp;
          });
          this.selectedMultiOption = "";
        } else {
          this.wrongAnswer = true;
          this.correctAnswer = false;
          this.isAnsCorrect = false;
          this.showMultiOption = false;
          this.isCorrect = 0;
          this.viewed = 1;
          this.attempted = 1;
          if (this.isPastYear === 0) {
            this.simulateUserResponse(
              this.isCorrect,
              this.timeTaken,
              this.selectedMultiOption,
              this.tryAgain,
              beginDate,
            );
            this.checkPromptsCondition();
          }
          this.timeTaken = 0;
          clearTimeout(this.timer);
          const Submitdata = {
            userTestSessionQuestionId: this.userTestSessionQuestionId,
            answerOption: String(this.selectedMultiOption),
            isCorrect: 0,
            isFirstTime: true,
            attemptedOn: beginDate,
          };
          Doubtapi.practicesessionsubmitapi(Submitdata, (resp) => {
            this.submit = resp;
          });
          this.selectedMultiOption = "";
        }
      }
      if (this.tryAgain === 1) {
        if (this.selectedMultiOption === this.questionAnswer) {
          this.correctAnswer = true;
          this.isAnsCorrect = true;
          this.showMultiOption = false;
          this.isCorrect = 0;
          this.viewed = 1;
          this.attempted = 1;
          if (this.isPastYear === 0) {
            this.simulateUserResponse(
              this.isCorrect,
              this.timeTaken,
              this.selectedMultiOption,
              this.tryAgain,
              beginDate,
            );
          }
          this.timeTaken = 0;
          clearTimeout(this.timer);
          const Submitdata = {
            userTestSessionQuestionId: this.userTestSessionQuestionId,
            answerOption: String(this.selectedMultiOption),
            isCorrect: 1,
            isFirstTime: false,
            attemptedOn: beginDate,
          };
          Doubtapi.practicesessionsubmitapi(Submitdata, (resp) => {
            this.submit = resp;
          });
          this.selectedMultiOption = "";
        } else {
          this.isCorrect = 0;
          this.viewed = 1;
          this.attempted = 1;
          if (this.isPastYear === 0) {
            this.simulateUserResponse(
              this.isCorrect,
              this.timeTaken,
              this.selectedMultiOption,
              this.tryAgain,
              beginDate,
            );
          }
          this.timeTaken = 0;
          clearTimeout(this.timer);
          const Submitdata = {
            userTestSessionQuestionId: this.userTestSessionQuestionId,
            answerOption: String(this.selectedMultiOption),
            isCorrect: 0,
            isFirstTime: false,
            attemptedOn: beginDate,
          };
          Doubtapi.practicesessionsubmitapi(Submitdata, (resp) => {
            this.submit = resp;
          });
          this.selectedMultiOption = "";
          this.wrongAnswer = true;
          this.showMultiOption = false;
        }
      }
    },
    submitNumericAnswer() {
      let beginDate = moment();
      beginDate = beginDate.format("YYYY-MM-DD HH:MM");
      this.promptVisible = false;
      this.isAnsCorrect = false;
      if (this.tryAgain === 0) {
        if (this.numericAnswer === Number(this.questionAnswer)) {
          this.correctAnswer = true;
          this.isAnsCorrect = true;
          this.showNumeric = false;
          this.isCorrect = 1;
          this.viewed = 1;
          this.attempted = 1;
          if (this.isPastYear === 0) {
            this.simulateUserResponse(
              this.isCorrect,
              this.timeTaken,
              this.numericAnswer,
              this.tryAgain,
              beginDate,
            );
            this.checkPromptsCondition();
          }
          this.timeTaken = 0;
          clearTimeout(this.timer);
          const Submitdata = {
            userTestSessionQuestionId: this.userTestSessionQuestionId,
            answerOption: String(this.numericAnswer),
            isCorrect: 1,
            isFirstTime: true,
            attemptedOn: beginDate,
          };
          Doubtapi.practicesessionsubmitapi(Submitdata, (resp) => {
            this.submit = resp;
          });
          this.numericAnswer = null;
        } else {
          this.wrongAnswer = true;
          this.correctAnswer = false;
          this.isAnsCorrect = false;
          this.showMultiOption = false;
          this.showNumeric = false;
          this.isCorrect = 0;
          this.viewed = 1;
          this.attempted = 1;
          if (this.isPastYear === 0) {
            this.simulateUserResponse(
              this.isCorrect,
              this.timeTaken,
              this.numericAnswer,
              this.tryAgain,
              beginDate,
            );
            this.checkPromptsCondition();
          }
          this.timeTaken = 0;
          clearTimeout(this.timer);
          const Submitdata = {
            userTestSessionQuestionId: this.userTestSessionQuestionId,
            answerOption: String(this.numericAnswer),
            isCorrect: 0,
            isFirstTime: true,
            attemptedOn: beginDate,
          };
          Doubtapi.practicesessionsubmitapi(Submitdata, (resp) => {
            this.submit = resp;
          });
          this.answerNumeric = this.numericAnswer;
          // this.numericAnswer = null;
        }
      }
      if (this.tryAgain === 1) {
        if (this.numericAnswer === Number(this.questionAnswer)) {
          this.isAnsCorrect = true;
          this.correctAnswer = true;
          this.showNumeric = false;
          this.isCorrect = 0;
          this.viewed = 1;
          this.attempted = 1;
          if (this.isPastYear === 0) {
            this.simulateUserResponse(
              this.isCorrect,
              this.timeTaken,
              this.numericAnswer,
              this.tryAgain,
              beginDate,
            );
          }
          this.timeTaken = 0;
          clearTimeout(this.timer);
          const Submitdata = {
            userTestSessionQuestionId: this.userTestSessionQuestionId,
            answerOption: String(this.numericAnswer),
            isCorrect: 1,
            isFirstTime: false,
            attemptedOn: beginDate,
          };
          Doubtapi.practicesessionsubmitapi(Submitdata, (resp) => {
            this.submit = resp;
          });
          this.answerNumeric = this.numericAnswer;
          this.numericAnswer = null;
        } else {
          this.isAnsCorrect = false;
          this.isCorrect = 0;
          this.viewed = 1;
          this.attempted = 1;
          if (this.isPastYear === 0) {
            this.simulateUserResponse(
              this.isCorrect,
              this.timeTaken,
              this.numericAnswer,
              this.tryAgain,
              beginDate,
            );
          }
          this.timeTaken = 0;
          clearTimeout(this.timer);
          const Submitdata = {
            userTestSessionQuestionId: this.userTestSessionQuestionId,
            answerOption: String(this.numericAnswer),
            isCorrect: 0,
            isFirstTime: false,
            attemptedOn: beginDate,
          };
          Doubtapi.practicesessionsubmitapi(Submitdata, (resp) => {
            this.submit = resp;
          });
          this.answerNumeric = this.numericAnswer;
          // this.numericAnswer = null;
          this.wrongAnswer = true;
          this.showNumeric = false;
        }
      }
    },
    nextQuestion() {
      this.conceptModal = false;
      this.conceptDiv = false;
      this.promptVisible = false;
      // this.kscData = [];
      // this.kscDiagram = "";
      this.answerGiven = "";
      this.isMarkForClarification = false;
      this.isMarkForRevision = false;
      this.showRevision = false;
      this.markForRevisionQuestionId = null;
      const UpdateSessiondata = {
        userTestSessionId: this.userTestSessionId,
      };
      MobileAPI.PracticeUpdateSession(UpdateSessiondata, (resp) => {
        if (resp.responseCode === 200) {
          if (this.isPastYear === 0) {
            const initialQuesiton = 0;
            this.newQuestionSelectionFunction(this.queGrpList, initialQuesiton);
          } else {
            this.pastYearQuestionIndex += 1;
            this.nextPastYearQuestion(this.queGrpList);
            localStorage.setItem("pastYearQuestionIndex", this.pastYearQuestionIndex);
          }
          if (Object.values(this.selectedQuestionData).length === 0) {
            this.showQueEndModal = true;
          } else {
            this.showQueEndModal = false;
            this.practiceNextNewQuestion();
          }
        }
      });

      if (this.testTypeId === 1 || this.testTypeId === 5) {
        this.showOptions = true;
        this.correctAnswer = false;
        this.wrongAnswer = false;
        this.showMultiOption = false;
        this.showNumeric = false;
        this.selectedOption = "";
        this.tryAgain = 0;
      } else if (this.testTypeId === 4) {
        this.showNumeric = true;
        this.correctAnswer = false;
        this.wrongAnswer = false;
        this.numericAnswer = null;
        this.showOptions = false;
        this.showMultiOption = false;
        this.tryAgain = 0;
      } else if (this.testTypeId === 3) {
        this.showMultiOption = true;
        this.correctAnswer = false;
        this.showNumeric = false;
        this.showOptions = false;
        this.wrongAnswer = false;
        this.tryAgain = 0;
        this.selectedOption = "";
      }
    },
    showConcept() {
      if (this.isMobileDevice === true) {
        this.conceptDiv = true;
      } else if (this.isMobileDevice === false) {
        this.conceptModal = true;
      }
      this.selectedConcept = 0;
      this.kscDiagram = "";
      this.selectedTab = null;
      this.kscDiagram = this.kscData[0].kscDiagramURL;
    },
    showKsc() {
      this.selectedTab = 1;
    },
    showFullSolution() {
      if (this.isMobileDevice === true) {
        this.conceptDiv = true;
      } else if (this.isMobileDevice === false) {
        this.conceptModal = true;
      }
      this.selectedConcept = null;
      this.selectedTab = 2;
    },
    showVideo() {
      this.selectedTab = 3;
    },
    viewConcept(data, index) {
      if (this.isMobileDevice === true) {
        this.conceptDiv = true;
      } else if (this.isMobileDevice === false) {
        this.conceptModal = true;
      }
      this.kscDiagram = "";
      this.selectedConcept = index;
      this.kscDiagram = data.kscDiagramURL;
      this.selectedTab = null;
    },
    showOther() {
      this.showRevisionModal = true;
    },
    closeModal() {
      this.showRevisionModal = false;
      this.showRevision = false;
      this.showClarification = false;
    },
    markForRevision() {
      this.showRevision = true;
      this.isMarkForRevision = true;
      this.markQuestionForRevision(this.isMarkForRevision);
      const data = {
        questionData: {
          UserId: parseInt(this.userDetails.UserId),
          QuestionId: this.questionId,
          SourceTypeId: 1,
          UserTestSessionQuestionId: this.userTestSessionQuestionId,
          CreatedBy: parseInt(this.userDetails.UserId),
        },
        serverToken: this.slToken,
      };
      MobileAPI.MarkForRevision(data, (response) => {
        this.markForRevisionQuestionId = response.data.Id;
        this.showRevision = false;
      });
    },
    removeRevision() {
      this.isMarkForRevision = false;
      this.markQuestionForRevision(this.isMarkForRevision);
      const data = {
        reviseQuestionId: this.userTestSessionQuestionId,
        serverToken: this.slToken,
      };
      MobileAPI.RemoveRevision(data, (response) => {
        if (response.responseCode === 200) {
          this.showRevision = false;
          this.markForRevisionQuestionId = null;
          this.MarkForRevision = true;
          this.RemoveFromRevision = false;
        }
      });
    },
    markQuesForClarification() {
      this.showClarification = true;
      if (!this.isMarkForClarification) {
        const data = {
          userTestSessionQuestionId: this.userTestSessionQuestionId,
          serverToken: this.slToken,
        };
        MobileAPI.QuestionMarkForClarification(data, (response) => {
          this.markForClarification = response.data;
          this.showClarification = false;
          this.isMarkForClarification = true;
          this.markQuestionForClarification(this.isMarkForClarification);
        });
      } else {
        const data = {
          UserTestSessionQuestionId: this.userTestSessionQuestionId,
        };
        MobileAPI.assignmentMarkForClarification(data, (response) => {
          this.markForClarification = response.data;
          this.showClarification = false;
          this.isMarkForClarification = false;
          this.markQuestionForClarification(this.isMarkForClarification);
        });
      }
    },
    tryAgainQuestion() {
      this.conceptModal = false;
      this.conceptDiv = false;
      this.questionTimer();
      if (this.testTypeId === 1 || this.testTypeId === 5) {
        this.tryAgain = 1;
        this.firstAttempt = false;
        this.showOptions = true;
        this.wrongAnswer = false;
        this.correctAnswer = false;
        this.isAnsCorrect = false;
      } else if (this.testTypeId === 3) {
        this.showMultiOption = true;
        this.firstAttempt = false;
        this.correctAnswer = false;
        this.wrongAnswer = false;
        this.tryAgain = 1;
        this.isAnsCorrect = false;
      } else if (this.testTypeId === 4) {
        this.showNumeric = true;
        this.correctAnswer = false;
        this.wrongAnswer = false;
        this.tryAgain = 1;
        this.isAnsCorrect = false;
        this.firstAttempt = false;
      }
    },
    pauseSession() {
      localStorage.removeItem("PracticeSessionRefreshData");
      localStorage.removeItem("isPastYear");
      localStorage.removeItem("pastYearQuestionIndex");
      localStorage.removeItem("isDppSession");
      localStorage.removeItem("UserTestSessionId");
      for (let i = 0; i < this.questionListArray.length; i += 1) {
        this.practiceSessionScore.push({
          netGroupScore: this.questionListArray[i].netGroupScore,
          groupCorrectSCore: this.questionListArray[i].groupCorrectScore,
          startIndexPosition: this.questionListArray[i].startIndexPosition,
          previousQnIndex: this.selectedQuestionData.QuestionId,
        });
      }
      const pauseSession = {
        userTestSessionId: this.userTestSessionId,
        serverToken: this.slToken,
      };
      clearTimeout(this.timer);
      const data = {
        practiceId: this.userTestSessionId,
        userId: Number(this.userDetails.UserId),
        practiceQuestionsList: this.questionListArray,
        currentQuestionGroupIndex: this.curentGroupArrayIndex,
        currentQuestionIndex: this.currentQnIndex,
        practiceScoreList: this.practiceSessionScore,
        kscList: this.kscList,
        subjectList: this.subjectList,
        chapterList: this.chapterList,
        practiceStatus: "Pause",
        practicePromptsData: this.promptsVariables,
        promptQuestionData: this.promptsQuestion,
        shownPromptsList: this.shownPrompts.length > 0 ? this.shownPrompts.join(",") : "",
      };
      MobileAPI.pausePracticeSession(data, (response) => {
        this.pausedSession = response.data;
      });
      MobileAPI.pauseSession(pauseSession, (response) => {
        if (response.responseCode === 200) {
          this.pauseModal = false;
          this.$router.push({
            name: "PracticeHome",
            params: {
              LpsId: this.lpsId ? this.lpsId : 0,
              LpsEndTime: this.lpsEndTime ? this.lpsEndTime : 0,
            },
          });
        }
      });
    },
    endModalOpen() {
      this.endModal = true;
    },
    cancelEndModal() {
      this.endModal = false;
      this.reportModal = false;
      this.pauseModal = false;
      this.noEasyQuestion = false;
      this.noHardQuestion = false;
      this.noMoreNewQuestion = false;
    },
    showReportModal() {
      this.reportModal = true;
    },
    showSolution() {
      if (this.isMobileDevice === true) {
        this.conceptDiv = true;
      } else if (this.isMobileDevice === false) {
        this.conceptModal = true;
      }
      this.selectedTab = 2;
      this.selectedConcept = null;
    },
    endPracticeSession() {
      localStorage.removeItem("PracticeSessionRefreshData");
      localStorage.removeItem("isPastYear");
      localStorage.removeItem("pastYearQuestionIndex");
      localStorage.removeItem("isDppSession");
      localStorage.removeItem("UserTestSessionId");
      for (let i = 0; i < this.questionListArray.length; i += 1) {
        this.practiceSessionScore.push({
          netGroupScore: this.questionListArray[i].netGroupScore,
          groupCorrectSCore: this.questionListArray[i].groupCorrectScore,
          startIndexPosition: this.questionListArray[i].startIndexPosition,
          previousQnIndex: this.questionListArray[i].previousQnIndex,
        });
      }
      const data = {
        practiceId: this.userTestSessionId,
        userId: Number(this.userDetails.UserId),
        practiceQuestionsList: this.queGrpList,
        currentQuestionGroupIndex: this.curentGroupArrayIndex,
        currentQuestionIndex: this.currentQnIndex,
        practiceScoreList: this.practiceSessionScore,
        kscList: this.kscList,
        subjectList: this.subjectList,
        chapterList: this.chapterList,
        subjectName: this.subjectName,
        practiceLevel: this.practiceLevel,
        className: this.className,
        chapterName: this.chapterName,
        practiceStatus: "EndSession",
        practicePromptsData: this.promptsVariables,
        promptQuestionData: this.promptsQuestion,
        shownPromptsList: this.shownPrompts.length > 0 ? this.shownPrompts.join(",") : "",
      };
      MobileAPI.pausePracticeSession(data, (response) => {
        this.pausedSession = response.data;
      });
      const endsession = {
        userTestSessionId: this.userTestSessionId,
        serverToken: String(this.slToken),
      };
      clearTimeout(this.timer);
      this.$store.dispatch("showLoader", true);
      MobileAPI.Endsession(endsession, (response) => {
        this.$store.dispatch("showLoader", false);
        if (response.responseCode === 200) {
          this.$router
            .push({
              name: "PracticeSessionNewReport",
              params: {
                UserTestSessionId: this.userTestSessionId,
                Name: this.userDetails.Name,
                Chapters: this.chapterName,
                SubjectName: this.subjectName,
                StartedOn: this.startDate,
                fromPractice: 1,
                TestName: this.isLps ? "LPS" : "Practice Session",
              },
              query: {
                feedback: true,
              },
            })
            .catch(() => {});
        }
      });
    },
    similarQuestions() {
      const data = {
        userTestSessionQuestionId: this.userTestSessionQuestionId,
        userTestSessionId: this.userTestSessionId,
        courseId: this.courseId,
        serverToken: this.slToken,
      };
      this.$store.dispatch("showLoader", true);
      MobileAPI.practiceSimilarQuestions(data, (response) => {
        this.questionImage = response.data.data.questionData.questionDiagramURL;
        this.questionAnswer = response.data.data.questionData.answerOption;
        this.questionId = response.data.data.questionData.questionId;
        this.questionScore = response.data.data.questionData.questionScore;
        this.questionData = response.data.data.questionData;
        this.kscData = response.data.data.kscDataList;
        this.userTestSessionQuestionId = response.data.data.userTestSessionQuestionId;
        this.$store.dispatch("showLoader", false);
      });
      if (this.testTypeId === 1 || this.testTypeId === 5) {
        this.showOptions = true;
        this.correctAnswer = false;
        this.wrongAnswer = false;
        this.showMultiOption = false;
        this.showNumeric = false;
        this.selectedOption = "";
      } else if (this.testTypeId === 4) {
        this.showNumeric = true;
        this.correctAnswer = false;
        this.wrongAnswer = false;
        this.showMultiOption = false;
        this.showOptions = false;
        this.numericAnswer = null;
      } else if (this.testTypeId === 3) {
        this.showMultiOption = true;
        this.correctAnswer = false;
        this.wrongAnswer = false;
        this.showOptions = false;
        this.showNumeric = false;
        this.selectedOption = "";
      }
    },
    onFileLogoChange(e) {
      const files = e.target.files || e.dataTransfer.files;
      if (files.length > 3) {
        window.M.toast({
          html: "You are only allowed to upload a maximum of 3 files at a time",
          //   classes: "rounded",
        });
      } else {
        if (!files.length) return;
        this.$store.dispatch("showLoader", true);
        this.fileList = files;
        this.$store.dispatch("showLoader", false);
        this.createImage(files);
      }
    },
    createImage(files) {
      this.$store.dispatch("showLoader", true);
      this.RemoveButton = true;
      for (let index = 0; index < files.length; index += 1) {
        const file = files[index];
        this.imageList.push(file);
        const reader = new FileReader();
        reader.onload = (event) => {
          const image = event.target.result;
          this.images.push(image);
        };
        reader.readAsDataURL(files[index]);
      }
      this.$store.dispatch("showLoader", false);
    },
    removeSolution() {
      this.fileList = [];
      this.images = [];
    },
    submitSubjectiveQuestionNow() {
      this.$store.dispatch("showLoader", true);
      const user = this.$store.getters.user;
      const formData = new FormData();
      for (let index = 0; index < this.fileList.length; index += 1) {
        const file = this.fileList[index];
        formData.append("files", file);
      }
      // formData.append("files", this.files);
      formData.append("affiliationId", user.AffiliationId);
      formData.append("batchId", user.CenterCodeId);
      formData.append("courseId", user.CourseId);
      formData.append("subjectId", this.subjectId);
      formData.append("studentId", user.UserId);
      formData.append("userTestSessionId", this.userTestSessionId);
      formData.append("questionId", this.questionId);
      formData.append("maximumMarks", this.questionData.maximumMarks);
      formData.append("serverToken", this.slToken);
      //   if()
      MobileAPI.PracticeSessionSubjectiveType(formData, (response) => {
        this.$store.dispatch("showLoader", false);
        // this.UserProfile.Mobile = "";
        if (response.responseCode === 200) {
          this.UploadImageLater = true;
          // this.removeImage();
          // this.nextQuestion();
          const UpdateSessiondata = {
            userTestSessionId: this.userTestSessionId,
          };
          MobileAPI.PracticeUpdateSession(UpdateSessiondata, (resp) => {
            if (resp.responseCode === 200) {
              this.$store.dispatch("showLoader", true);
              this.fileList = [];
              this.subjectiveQuestionIndex += 1;
              this.nextSubjectiveQuestion(this.queGrpList);
              if (this.selectedQuestionData.length !== 0) {
                const dataObj = {
                  questionId: this.selectedQuestionData.QuestionId,
                  userId: Number(this.userDetails.UserId),
                  practiceId: this.userTestSessionId,
                };
                MobileAPI.getPracticeNextQuestionNew(dataObj, (response1) => {
                  let beginDate = moment();
                  beginDate = beginDate.format("YYYY-MM-DD HH:MM");
                  this.startDate = beginDate;
                  this.testTypeId = Number(response1.data.questionData.questionTypeId);
                  this.getQuestionData(response1);
                });
                this.showClarification = false;
                this.isMarkForClarification = false;
              } else {
                this.$router.push({
                  name: "Upload Later",
                  params: {
                    userTestSessionId: this.userTestSessionId,
                    Name: this.$store.getters.user.Name,
                    SubjectName: this.subjectName,
                    SubjectId: this.subjectId,
                    CourseId: this.courseId,
                  },
                });
              }
              this.$store.dispatch("showLoader", false);
            }
          });
        }
      });
    },
    skipQuestion() {
      // this.kscData = [];
      // this.kscDiagram = "";
      this.promptVisible = false;
      this.answerGiven = "";
      this.isSkipped = true;
      this.conceptModal = false;
      clearTimeout(this.timer);
      this.timeTaken = 0;
      const initialQuestion = 0;
      this.showClarification = false;
      this.showRevision = false;
      this.markForRevisionQuestionId = null;
      if (this.isPastYear === 0) {
        this.questionSkip(this.isSkipped, this.tryAgain, this.timeTaken);
        this.newQuestionSelectionFunction(this.queGrpList, initialQuestion);
        this.checkPromptsCondition();
      } else {
        this.pastYearQuestionIndex += 1;
        this.nextPastYearQuestion(this.queGrpList);
        localStorage.setItem("pastYearQuestionIndex", this.pastYearQuestionIndex);
      }
      const UpdateSessiondata = {
        userTestSessionId: this.userTestSessionId,
      };
      MobileAPI.PracticeUpdateSession(UpdateSessiondata, (resp) => {
        if (resp.responseCode === 200) {
          if (Object.values(this.selectedQuestionData).length === 0) {
            this.showQueEndModal = true;
          } else {
            this.practiceNextNewQuestion();
          }
        }
      });
      this.selectedOption = "";
      this.selectedMultiOption = "";
      this.numericAnswer = null;
      this.tryAgain = 0;
    },
    GetSubjectiveQuestionList() {
      const data = {
        userTestSessionId: this.userTestSessionId,
      };
      MobileAPI.GetSubjectQuestionList(data, (response) => {
        this.ViewSubjectiveTypeQuestion = false;
        this.SubmitSubjectiveTypeQuestion = true;
        this.subjectiveQuestionList = response.data;
        if (response.data.solutionUrlList !== null) {
          this.SolutionImageUploaded = true;
          this.SolutionUploadImage = false;
        } else {
          this.SolutionImageUploaded = false;
          this.SolutionUploadImage = true;
        }
      });
    },
    uploadQuestionLater() {
      const UpdateSessiondata = {
        userTestSessionId: this.userTestSessionId,
      };
      MobileAPI.PracticeUpdateSession(UpdateSessiondata, (resp) => {
        if (resp.responseCode === 200) {
          this.$store.dispatch("showLoader", true);
          this.subjectiveQuestionIndex += 1;
          this.nextSubjectiveQuestion(this.queGrpList);
          if (this.selectedQuestionData.length !== 0) {
            const data = {
              questionId: this.selectedQuestionData.QuestionId,
              userId: Number(this.$store.getters.user.UserId),
              practiceId: this.userTestSessionId,
            };
            MobileAPI.getPracticeNextQuestionNew(data, (response) => {
              this.$store.dispatch("showLoader", false);
              this.testTypeId = Number(response.data.questionData.questionTypeId);
              this.getQuestionData(response);
            });
            this.showClarification = false;
            this.isMarkForClarification = false;
          } else {
            this.$router.push({
              name: "Upload Later",
              params: {
                userTestSessionId: this.userTestSessionId,
                Name: this.$store.getters.user.Name,
                SubjectName: this.subjectName,
                SubjectId: this.subjectId,
                CourseId: this.courseId,
                isLps: this.isLps ? 1 : 0,
              },
            });
          }
        }
      });
    },
    showUploadLater() {
      this.$router.push({
        name: "Upload Later",
        params: {
          userTestSessionId: this.userTestSessionId,
          Name: this.$store.getters.user.Name,
          SubjectName: this.subjectName,
          SubjectId: this.subjectId,
          CourseId: this.courseId,
          isLps: this.isLps ? 1 : 0,
        },
      });
    },
    showPrompt() {
      const x = document.getElementById("prompt-toast");
      x.className = "promptBox";
      setTimeout(() => {
        x.className = x.className.replace("promptBox", "none");
      }, 7000);
    },
    start() {
      setTimeout(() => {
        this.$confetti.start({
          particles: [
            {
              type: "rect",
            },
            {
              type: "rect",
            },
            {
              type: "heart",
              colors: ["#EF727A", "#5C8EFF"],
            },
          ],
          particlesPerFrame: 5,
          windSpeedMax: -0.8,
          //   defaultDropRate: 10,
          //   defaultSize: 10,
        });
      }, 1000);
      setTimeout(() => {
        this.stop();
      }, 3200);
    },
    stop() {
      this.$confetti.stop();
      this.option = null;
    },
  },
  components: {
    Modal2,
    Modal3,
    DoughnutChart,
    Prompts,
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap");

.practice-session {
  font-family: "Open Sans" !important;
}

.blk-text {
  color: #333333;
}

.blue-text {
  color: #16bcb2 !important;
}

.red-text {
  color: #ff7675 !important;
}

.blue-bg {
  background-color: #3751ff !important;
}

.white-bg {
  background-color: #fff !important;
}

.white-font {
  color: #fff !important;
}

.ln-18 {
  line-height: 18px !important;
}

.ln-20 {
  line-height: 20px !important;
}

.ln-16 {
  line-height: 16px !important;
}

.ln-17 {
  line-height: 17px !important;
}

.ln-27 {
  line-height: 27.24px !important;
}

.flex {
  display: flex !important;
}

.relative {
  position: relative !important;
}

.absolute {
  position: absolute !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row {
  flex-direction: row !important;
}

.content-btw {
  justify-content: space-between !important;
}

.content-cntr {
  justify-content: center !important;
}

.content-even {
  justify-content: space-evenly !important;
}

.content-around {
  justify-content: space-around !important;
}

.content-left {
  justify-content: left !important;
}

.column {
  flex-direction: column !important;
}

.flex-center {
  justify-content: center;
}

.fs-12 {
  font-size: 12px !important;
}

.fs-13 {
  font-size: 13px !important;
}

.fs-14 {
  font-size: 14px !important;
}

.fs-15 {
  font-size: 15px !important;
}

.fs-18 {
  font-size: 18px !important;
}

.fs-20 {
  font-size: 20px !important;
}

.fs-24 {
  font-size: 24px !important;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.active {
  background: #37841c !important;
  color: #ffffff !important;
  cursor: pointer !important;
  pointer-events: all !important;
}

.deactive {
  background: grey !important;
  color: #ffffff !important;
  cursor: not-allowed !important;
  pointer-events: none !important;
}

.question-image {
  max-height: 380px !important;
  max-width: 900px !important;
  min-width: 600px !important;
  border: 1px solid #f5f5f9;
}

.active1 {
  background: #f0f2ff !important;
  border: 1px solid #d6daf7 !important;
  font-weight: 600 !important;
  color: #333333 !important;
}

.wrong-text1 {
  display: none;
}

.none {
  display: none !important;
}

.session-box {
  box-sizing: border-box;
  max-width: 1174px;
  min-width: 300px;
  height: 615px;
  border: 1px solid #e0e4f0;
  border-radius: 6px 6px 0px 0px;
  margin-top: 20px;
  background: #ffffff !important;
  position: relative;
}

.header-box {
  box-sizing: border-box;
  position: relative;
  max-width: 1175px;
  min-width: 300px;
  height: 70px;
  border: 1px solid #e0e4f0;
  border-radius: 6px 6px 0px 0px;
}

.header-options {
  height: 59px;
  margin: 5px 20px 10px 22px;
  opacity: 1 !important;
}

.header-right {
  display: flex !important;
}

.header-left {
  margin-top: 13px;
}

.header-right-button {
  width: 20px;
  height: 55px;
  background: #693383;
  border-radius: 4px;
  border: none;
  margin: 3px 10px 5px 0px;
}

.button-size {
  width: 40px !important;
  height: 34px !important;
  cursor: pointer;
}

.score-button {
  width: 101px;
  height: 34px;
  background: #ffffff;
  border-radius: 6px;
  border: none;
  color: #3751ff;
  cursor: pointer;
}
.diagramURL {
  overflow: hidden !important;
  height: auto;
  max-width: 100%;
  flex: 1 1 auto !important;
  -ms-flex: 1 1 auto !important;
}

.answer-section {
  box-sizing: border-box;
  position: relative;
  bottom: -225px;
  max-width: 1134px;
  min-width: 300px;
  height: 82px;
  margin: 0px 20px 20px 20px;
  background: #ffffff;
  border: 1px solid #e0e4f0;
  box-shadow: 0px 0px 25px rgba(139, 151, 186, 0.2) !important;
  border-radius: 6px;
  z-index: 100;
}
.answer-section-latex {
  box-sizing: border-box;
  position: relative;
  bottom: -38px;
  max-width: 1134px;
  min-width: 300px;
  height: 82px;
  margin: 0px 20px 20px 20px;
  background: #ffffff;
  border: 1px solid #e0e4f0;
  box-shadow: 0px 0px 25px rgba(139, 151, 186, 0.2) !important;
  border-radius: 6px;
  z-index: 100;
}
.latex-section::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.023);
  background-color: #f9f9f9;
  border-radius: 5px;
}

.latex-section::-webkit-scrollbar {
  width: 6px;
  background-color: #f9f9f9;
}

.latex-section::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #999fb7;
}
.selection-btn {
  box-sizing: border-box;
  min-height: 41.22px;
  min-width: 41.22px;
  border: 1px solid rgba(21, 15, 45, 0.12);
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  display: flex;
  background: #fff;
  margin-right: 10px;
}
.answer-section-1 {
  box-sizing: border-box;
  position: relative;
  bottom: -12px;
  max-width: 1134px;
  min-width: 300px;
  height: 82px;
  margin: 0px 20px 20px 20px;
  background: #ffffff;
  border: 1px solid #e0e4f0;
  box-shadow: 0px 0px 25px rgba(139, 151, 186, 0.2) !important;
  border-radius: 6px;
}

.answer-objective {
  margin: 16px 20px 8px 20px;
  height: 51px !important;
  max-width: 1090px !important;
}

.answer-button {
  width: 60px;
  height: 51px;
  background: #ffffff;
  border: 1px solid #e0e4f0;
  border-radius: 4px;
  margin-right: 20px;
  cursor: pointer;
}

.answer-button:hover {
  width: 60px;
  height: 51px;
  background: #ffffff;
  border: 1px solid #37841c;
  border-radius: 4px;
  color: #37841c;
}

.answer-section-hr {
  width: 34px;
  border: 1px solid #dedcdc;
  transform: rotate(90deg);
}

.submit-button {
  width: 99px;
  height: 34px;
  background: #fbfbfb;
  border-radius: 4px;
  border: none;
  color: #e0e4f0;
  margin-right: 20px;
  cursor: not-allowed;
  pointer-events: none;
}

.skip-button {
  width: 78px;
  height: 34px;
  background: #ffffff;
  border: 1px solid #37841c;
  border-radius: 4px;
  color: #37841c;
  cursor: pointer;
}

.skip-button:hover {
  color: #ffffff;
  background: #37841c;
}

.option-button {
  width: 17px;
  height: 42px;
  border-radius: 4px;
  border: none;
  /* margin: 24px 20px 24px 0px; */
  cursor: pointer;
}

.option-button-1 {
  width: 17px;
  height: 42px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  margin-top: -4px;
}

.option-img {
  width: 4px;
  height: 19px;
  display: flex;
  justify-content: center;
}

.correct-image {
  width: 66px;
  height: 50px;
  margin-right: 15px;
}

.concept-button {
  width: 145px;
  height: 34px;
  background: #f3f5fc;
  border-radius: 4px;
  border: none;
  color: #3751ff;
  margin-right: 20px;
  cursor: pointer;
}

.concept-button:hover {
  background: #3751ff;
  color: #ffffff;
}

.concept-button-1 {
  width: 145px;
  height: 34px;
  background: #fbfbfb;
  border-radius: 4px;
  border: none;
  color: #e0e4f0;
  margin-right: 20px;
  cursor: not-allowed;
}

.concept-image-2 {
  max-height: 400px !important;
  max-width: 900px !important;
  min-width: 600px !important;
  border: 1px solid #f5f5f9;
}

.similar-button {
  width: 145px;
  height: 34px;
  background: #ffffff !important;
  border: 1px solid #37841c !important;
  border-radius: 4px;
  color: #37841c !important;
  margin-right: 20px;
  cursor: pointer;
}

.similar-button:hover {
  background: #37841c;
  color: #ffffff;
}

.next-button {
  width: 83px;
  height: 34px;
  background: #ffffff;
  /* Action 1 */
  border: 1px solid #37841c;
  border-radius: 4px;
  margin-right: 20px;
  color: #37841c;
  cursor: pointer;
}

.next-button:hover {
  background: #37841c;
  color: #ffffff;
}

.correct-section {
  height: 51px !important;
  max-width: 1090px !important;
  margin: 16px 20px 8px 20px !important;
}

.revision-modal {
  position: absolute;
  width: 304px;
  height: 80px;
  right: -20px;
  top: -16px;

  background: #ffffff;
  border-radius: 0px 5px 5px 0px;
  border-left: 1px solid #e0e4f0;
}

.revision-modal-1 {
  position: absolute;
  width: 304px;
  height: 80px;
  right: 0px;
  top: 0px;

  background: #ffffff;
  border-radius: 0px 5px 5px 0px;
  border-left: 1px solid #e0e4f0;
}

.modal-close {
  width: 34px;
  height: 34px;
  background: #ff7675;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.revision-button {
  width: 194px;
  height: 28px;
  background: #f3f5fc;
  border-radius: 4px;
  color: #3751ff;
  border: none;
  padding: 5px;
  cursor: pointer;
  /* display: flex; */
}

.revision-button-1 {
  width: 194px;
  height: 28px;
  background: #3751ff;
  border-radius: 4px;
  color: #f3f5fc;
  border: none;
  padding: 5px;
  cursor: pointer;
  /* display: flex; */
}

.revision-img {
  width: 18px;
  height: 18px;
  margin-right: 5px;
}

.revision-img-1 {
  width: 18px;
  height: 18px;
  margin-right: 5px;
}

.image-section {
  max-width: 880px;
  min-width: 300px;
  height: 185px;
  min-height: 85px;
  padding: 11px;
}

.earn-head {
  position: relative;
  width: 100%;
  background: #f3f5fc;
  padding: 15px;
  border-radius: 5px 5px 0 0;
}

.earn-head-1 {
  position: relative;
  width: 100%;
  /* background: #F3F5FC; */
  padding: 15px 0;
  border-radius: 5px 5px 0 0;
  border-bottom: 1px solid #e0e4f0;
}

.graph-area {
  border-right: 1px solid #e0e4f0;
}

.card-area {
  padding: 20px !important;
}

.prompt-class {
  position: relative;
  right: -770px;
  bottom: -50px;
}

.green-indicator {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #00b894;
  border: 1px solid #ffffff;
  box-shadow: 0px 4px 6px rgba(0, 184, 148, 0.3);
}

.red-indicator {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #ff7675;
  border: 1px solid #ffffff;
  box-shadow: 0px 4px 6px rgba(255, 118, 117, 0.3);
}

.yellow-indicator {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #fdcb6e;
  border: 1px solid #ffffff;
  box-shadow: 0px 4px 6px rgba(248, 183, 63, 0.3);
}

.concept-list {
  margin-top: 10px;
  max-height: 352px !important;
  overflow-y: scroll;
  overflow-x: hidden;
  white-space: nowrap;
}

.concept-list::-webkit-scrollbar {
  display: none;
}

.concept-card {
  width: 270px;
  height: 77px;
  background: #ffffff;
  border: 1px solid #e0e4f0;
  border-radius: 5px;
  margin-top: 10px;
}

.earn-close {
  background-color: #ff7675 !important;
  border: 1px solid #e0e4f0;
  border-radius: 3px;
}

.concept-modal {
  max-width: 1173px;
}

.selected {
  color: #3751ff;
  font-weight: 700 !important;
  font-size: 20px;
  line-height: 27px;
  border-bottom: 2px #3751ff;
}

.concept-modal-button {
  width: 115px;
  height: 30px;
  background: #f8f8f8;
  border-radius: 4px;
  border: none;
  font-weight: 400;
  color: #333333;
  cursor: pointer;
  margin-right: 20px;
  opacity: 0.65;
}

.end-modal {
  width: 450px;
  height: 356px;
  background: #ffffff;
  border-radius: 10px;
}

.no-ques-modal {
  width: 520px;
  height: 380px;
  background: #ffffff;
  border-radius: 10px;
}
.latex-section {
  height: 380px !important;
  /* max-width: 900px !important; */
  min-width: 600px !important;
  border: 1px solid #f5f5f9;
  overflow-y: scroll;
  padding: 10px;
}

.report-modal {
  width: 450px;
  height: 560px !important;
  background: #ffffff;
  border-radius: 10px !important;
}

.end-cancel-button {
  width: 114px;
  height: 34px;
  background: #ffffff;
  border: 1px solid #37841c;
  border-radius: 4px;
  background: #ffffff;
  color: #37841c;
  cursor: pointer;
}

.end-cancel-button:hover {
  background: #37841c !important;
  color: #ffffff !important;
}

.end-confirm-button {
  width: 114px;
  height: 34px;
  background: #ffffff;
  border: 1px solid #ff7675;
  border-radius: 4px;
  color: #ff7675;
  cursor: pointer;
}

.end-confirm-button:hover {
  background: #ff7675 !important;
  color: #ffffff !important;
}

.subjective-section {
  height: 34px;
  margin: 24px 24px 24px 20px;
  max-width: 1090px !important;
}

.progress1 {
  position: relative;
  max-width: 100%;
  height: 6px;
  background: #f2f3f8;
  /* margin-bottom: 15px; */
}

.progress-bar1 {
  position: absolute;
  height: 100%;
  border-radius: 30px;
}

.question-type-button {
  /* width: 121px; */
  height: 21px;
  background: #f6e2ff;
  border-radius: 4px;
  font-weight: 600;
  font-size: 11px;
  line-height: 15px;
  align-items: center;
  display: flex;
  justify-content: center;
  color: #693383;
  margin-right: 9px;
  padding-left: 10px;
  padding-right: 10px;
}

.basic-button {
  background: #16a085;
  color: white;
  min-width: 41px;
  height: 21px;
  /* width: max-content; */
  font-weight: 600;
  font-size: 11px;
  line-height: 15px;
  /* opacity: 0.6; */
  border-radius: 4px;
  justify-content: center;
  display: flex;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  margin-right: 20px;
}

.easy-button {
  background: #8bc42e;
  color: white;
  /* min-width: 41px; */
  height: 21px;
  font-weight: 600;
  font-size: 11px;
  line-height: 15px;
  /* opacity: 0.6; */
  border-radius: 4px;
  justify-content: center;
  display: flex;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  margin-right: 20px;
}

.normal-button {
  background: #ffbf44;
  color: white;
  height: 21px;
  /* min-width: 41px; */
  /* opacity: 0.6; */
  border-radius: 4px;
  font-weight: 600;
  font-size: 11px;
  line-height: 15px;
  justify-content: center;
  display: flex;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  margin-right: 20px;
}

.hard-button {
  background: #ff835c;
  color: white;
  height: 21px;
  /* min-width: 41px; */
  /* opacity: 0.6; */
  border-radius: 4px;
  font-weight: 600;
  font-size: 11px;
  line-height: 15px;
  justify-content: center;
  display: flex;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  margin-right: 20px;
}

.tough-button {
  background: #b03e3e;
  color: white;
  height: 21px;
  /* min-width: 41px; */
  /* opacity: 0.6; */
  border-radius: 4px;
  font-weight: 600;
  font-size: 11px;
  line-height: 15px;
  justify-content: center;
  display: flex;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  margin-right: 20px;
}

.progress-bar1.transparent {
  background: transparent;
}

.progress-bar1.green {
  background: #16bcb2 !important;
}

.image-button {
  border: 1px solid #e0e4f0;
  border-radius: 4px;
  width: 117px;
  height: 34px;
  background: #ffffff;
  color: #3751ff;
  padding: 7px 10px;
  margin-right: 12px;
}

.numeric-field {
  background: #ffffff;
  width: 380px !important;
  height: 51px !important;
  border: 1px solid #e0e4f0 !important;
  border-radius: 4px !important;
}

.number-input input {
  padding-left: 10px !important;
}

.score-card {
  width: 630px;
  height: 472px;
  position: absolute;
  background: #ffffff;
  border: 1px solid #e0e4f0;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  right: 20px;
  top: 20px;
}

.full-solution-image-2 {
  max-width: 900px !important;
  min-width: 600px !important;
  max-height: 400px;
  border: 1px solid #f5f5f9;
}

input::placeholder {
  font-weight: 600 !important;
  font-size: 20px !important;
  line-height: 27px !important;
  color: #333333 !important;
  /* padding: 15px !important; */
}

input[type="text"] {
  font-weight: 600 !important;
  font-size: 20px !important;
  line-height: 27px !important;
  color: #333333 !important;
  resize: vertical;
  padding-left: 15px;
  /* margin-right: 15px !important; */
}

input {
  margin-right: 15px !important;
}

.report-label {
  margin-top: 10px;
}

.concept-head-button {
  padding-right: 50px !important;
}

.promptBox {
  position: absolute;
  bottom: 18%;
  right: 20px;
  z-index: 10;
  animation-name: fadeInBottom;
  animation-duration: 1s;
  width: 349px;
}

@keyframes fadeInBottom {
  from {
    opacity: 0;
    transform: translateY(100%);
  }

  to {
    opacity: 1;
  }
}

[type="radio"]:checked + span:after,
[type="radio"].with-gap:checked + span:before,
[type="radio"].with-gap:checked + span:after {
  border: 2px solid #3751ff;
}

[type="radio"]:checked + span:after,
[type="radio"].with-gap:checked + span:after {
  background-color: #3751ff;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
  border-bottom: none !important;
}

@media screen and (max-width: 1206px) {
  .wrong-text {
    display: none !important;
  }

  .wrong-text1 {
    display: unset !important;
  }
}

@media screen and (max-width: 1125px) {
  .wrong-text1 {
    display: none !important;
  }
}

.rotate {
  background: url("../assets/landscape.png");
  height: 100px;
  width: 100px;
  margin: 0 auto;
}

.portrait {
  display: none;
}

@media screen and (max-width: 1100px) {
  .answer-objective-div {
    width: 680px !important;
  }

  .question-image {
    max-height: 380px !important;
    max-width: 810px !important;
    min-width: 550px !important;
    border: 1px solid #f5f5f9;
  }

  .full-solution-image-2 {
    max-width: 810px !important;
    min-width: 500px !important;
    max-height: 400px;
    border: 1px solid #f5f5f9;
  }

  .concept-image-2 {
    max-height: 400px !important;
    max-width: 810px !important;
    min-width: 600px !important;
    border: 1px solid #f5f5f9;
  }
}

@media screen and (max-width: 1040px) {
  .concept-modal {
    max-width: 800px !important;
  }

  .concept-image {
    max-width: 750px !important;
  }

  .full-solution-image {
    max-width: 750px !important;
  }

  .question-image {
    max-width: 750px !important;
  }

  .full-solution-image-2 {
    max-width: 750px !important;
    min-width: 500px !important;
    max-height: 400px;
    border: 1px solid #f5f5f9;
  }

  .concept-image-2 {
    max-height: 400px !important;
    max-width: 750px !important;
    min-width: 600px !important;
    border: 1px solid #f5f5f9;
  }
}

@media screen and (max-width: 900px) {
  .concept-button {
    width: 135px !important;
  }

  .similar-button {
    width: 135px !important;
  }

  .answer-objective-div {
    width: 670px !important;
  }

  .session-box {
    height: 690px !important;
  }

  @media screen and (max-width: 895px) {
    .concept-modal {
      max-width: 650px !important;
      height: 656px;
      min-width: 450px;
    }

    .concept-modal-button {
      font-size: 15px !important;
      height: 29px;
    }

    .earn-head {
      width: 100%;
    }

    .concept-image-2 {
      max-width: 520px !important;
      max-height: 385px !important;
    }

    .full-solution-image-2 {
      max-width: 520px !important;
      max-height: 385px !important;
    }

    .selected {
      color: #3751ff;
      font-weight: 700 !important;
      font-size: 18px;
      line-height: 23px;
      border-bottom: 2px #3751ff;
    }

    .concept-head-button {
      font-weight: 500;
      font-size: 15px;
      padding-right: 50px !important;
    }
  }

  @media screen and (max-width: 750px) {
    .chapter-name {
      font-size: 15px !important;
      line-height: 20px !important;
    }

    .class-name {
      font-size: 13px !important;
    }

    .question-image {
      max-width: 620px !important;
      min-width: 550px !important;
    }

    .image-section {
      max-width: 640px !important;
      min-width: 550px !important;
    }

    .answer-objective-div {
      width: 525px !important;
    }

    .concept-button {
      width: 115px !important;
    }

    .concept-button-1 {
      width: 115px !important;
    }

    .answer-button {
      width: 51px !important;
    }

    .full-solution-image-2 {
      max-width: 650px !important;
      min-width: 500px !important;
      max-height: 400px;
      border: 1px solid #f5f5f9;
    }

    .earn-head {
      width: 100%;
    }

    .concept-image {
      width: 396px !important;
      height: 297px !important;
    }

    .full-solution-image {
      width: 396px !important;
      height: 297px !important;
    }
  }

  @media screen and (max-width: 630px) {
    .card {
      display: none;
    }

    .portrait {
      display: block;
      background: #3a322f;
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      z-index: 1001;
      text-align: center;
      color: #ffffffe6;
      left: 0;
      padding: 0 30px;
    }

    .earn-head {
      width: 90%;
    }

    .concept-image {
      width: 396px !important;
      height: 297px !important;
    }

    .full-solution-image {
      width: 396px !important;
      height: 297px !important;
    }
  }
}
</style>
